import React, { useContext, useEffect, useRef, useState } from 'react';
import { Switch } from 'react-router';
import ProtectedRoute from '../../components/ProtectedRoute';
import GameLockList from '../../components/Game/GameLockList';
import GameDashboardView from './GameDashboardView';
import GameLockView from './GameLockView';
import GamePuzzleView from './GamePuzzleView';
import DateCountdown from '../../components/Countdown/DateCountdown';
import PuzzleButtons from '../../components/Game/PuzzleButtons';
import { RevengeContext } from '../../services/RevengeContext';

const GameContainer = () => {
  const [puzzle, setPuzzle] = useState(null);
  const { gameContext, puzzleCode, setShowBombBackground } = useContext(RevengeContext);

  useEffect(() => {
    setShowBombBackground(true);

    return () => {
      setShowBombBackground(false);
    };
  }, []);

  useEffect(() => {
    if (puzzleCode) {
      const newPuzzle = gameContext.game.puzzles.filter((puzzle) => puzzle.code === puzzleCode)[0];
      setPuzzle(newPuzzle);
    } else {
      setPuzzle(null);
    }

    return () => {};
  }, [gameContext, puzzleCode]);

  return (
    <div className="game-container mb-3">
      <Switch>
        <ProtectedRoute path="/game/puzzle/:puzzleCode/lock/:lockSequence" exact render={() => <GameLockView />} />
        <ProtectedRoute path="/game/puzzle/:puzzleCode" exact render={() => <GamePuzzleView />} />
        <ProtectedRoute path="/game" exact render={() => <GameDashboardView />} />
      </Switch>

      <PuzzleButtons />

      {puzzle && puzzle.code !== 'BOMB' && <GameLockList puzzle={puzzle} />}

      <div className="bomb-ticker">
        <DateCountdown />
      </div>
    </div>
  );
};

export default GameContainer;
