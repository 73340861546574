import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import RevengeContext from '../../services/RevengeContext';
import './DateCountdown.css';

const DateCountdown = () => {
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [diff, setDiff] = useState(0);
  const history = useHistory();
  let wasEverAlive = false;

  const { gameContext, fetchGameContext, dateOffsetRef } = useContext(RevengeContext);

  useEffect(() => {
    let tickId;

    const targetDate = gameContext.game.started ? new Date(gameContext.game.ends) : new Date(gameContext.game.starts);

    const tick = (tickId) => {
      let diff2;

      if (gameContext?.game?.summary?.timeRemaining) {
        tickId && clearInterval(tickId);

        if (gameContext.game.summary.timeRemaining.startsWith('-')) {
          setDiff(-1);
        } else {
          setDiff(1);
          const parts = gameContext.game.summary.timeRemaining.split(':');

          const hourParts = parts[0].split('.');
          if (hourParts.length > 1) {
            let hours = parseInt(hourParts[0]) * 24 + parseInt(hourParts[1]);
            if (hours > 99) {
              hours = 99;
            }

            setHour(hours);
          } else {
            setHour(parts[0]);
          }

          setMin(parts[1]);
          setSec(parts[2]);
        }
      } else {
        const currentDate = new Date() - (dateOffsetRef.current || 0);
        diff2 = targetDate - currentDate;

        setHour(Math.floor((diff2 / 3600000) % 24));
        setMin(Math.floor((diff2 / 60000) % 60));
        setSec(Math.floor((diff2 / 1000) % 60));
        setDiff(diff2);
      }

      if (diff2 < 0) {
        tickId && clearInterval(tickId);

        if (!gameContext.game.started) {
          window.location.reload();
          return;
        }

        if (wasEverAlive && !gameContext.team?.admin) {
          setTimeout(() => {
            fetchGameContext().then(() => {
              history.push(`/game/bomb-exploded?returnUrl=${window.location.pathname}`);
            });
          }, 2000);
        }
      } else {
        wasEverAlive = true;
      }
    };

    if (gameContext.game?.summary?.timeRemaining) {
      tick(null);
    } else {
      tickId = setInterval(() => tick(tickId), 1000);
      tick(tickId);
    }

    return () => {
      if (tickId) {
        clearInterval(tickId);
      }
    };
  }, [gameContext.game.starts, gameContext.game.started, gameContext.game?.summary?.timeRemaining, gameContext.game?.ends]);

  if (diff < 0) {
    if (gameContext.game.started) {
      return (
        <span className="odometer-block finished">
          <FontAwesomeIcon icon="skull-crossbones" />
          <FontAwesomeIcon icon="skull-crossbones" />
          <FontAwesomeIcon icon="skull-crossbones" />
        </span>
      );
    } else {
      return <span className="odometer-block finished">Here we go!</span>;
    }
  }

  let hours = hour.toString().padStart(2, '0');
  let mins = min.toString().padStart(2, '0');
  let secs = sec.toString().padStart(2, '0');

  return (
    <span className="odometer-block">
      <span className="hour">
        <span>{hours[0]}</span>
        <span>{hours[1]}</span>
      </span>
      <span className="sep">:</span>
      <span className="min">
        <span>{mins[0]}</span>
        <span>{mins[1]}</span>
      </span>
      <span className="sep">:</span>
      <span className="sec">
        <span>{secs[0]}</span>
        <span>{secs[1]}</span>
      </span>
    </span>
  );
};

export default DateCountdown;
