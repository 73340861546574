import React from 'react';
import CountdownWaitingRoom from '../components/Countdown/CountdownWaitingRoom';
import WaitingRoomTeams from '../components/WaitingRoomTeams';

const WaitingRoom = () => {
  return (
    <div className="waiting-room mb-3">
      <CountdownWaitingRoom />
      <WaitingRoomTeams />

      <h3 className="orbi mt-3">Wil je de trailer nog eens terugkijken?</h3>
      <video autoPlay={false} controls={true} muted={false} poster="/images/video-poster-trailer.jpg">
        <source src="https://cdn.dewraakvanlind.nl/videos/trailer.mp4" type="video/mp4" />
        <track label="Nederlands" kind="subtitles" srcLang="nl" src="/subtitles/trailer.vtt" default></track>
      </video>
    </div>
  );
};

export default WaitingRoom;
