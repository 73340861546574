import React from 'react';
import { useParams } from 'react-router-dom';
import TeamJoin from '../../components/Team/TeamJoin';

export const TeamJoinView = () => {
  const { code } = useParams();

  return (
    <div className="team-join">
      <div className="mb-5">
        <TeamJoin urlCode={code} />
      </div>
    </div>
  );
};

export default TeamJoinView;
