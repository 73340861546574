import moment from 'moment';

const isToday = (date) => {
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
};

const FormatDate = ({ date, showToday = true }) => {
  if (!date) {
    return '--';
  }

  date = new Date(date);
  if (isToday(date)) {
    return `${showToday ? 'vandaag, ' : ''}${moment(date).format('HH:mm')}`;
  }

  return moment(date).format('DD-MM-yy HH:mm');
};

const FormatParseableDate = (date) => {
  date = new Date(date);
  return moment(date).format('yyyy-MM-DD HH:mm');
};

const FormatInputDate = (date) => {
  if (!date) {
    return '';
  }

  date = new Date(date);
  return moment(date).format('yyyy-MM-DDTHH:mm:ss');
};

const FormatDuration = (str) => {
  const duration = moment.duration(str);
  return `${duration._data.hours}u ${duration._data.minutes}m`;
};

const getDateOffset = (dateString) => {
  const browserDate = new Date();
  const serverDate = new Date(dateString);

  // console.log('browserDate', browserDate);
  // console.log('serverDate', serverDate);

  return browserDate - serverDate;
};

export { isToday, FormatDate, FormatParseableDate, FormatDuration, FormatInputDate, getDateOffset };
