import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import GameHeader from '../../components/Admin/GameHeader';
import TeamsList from '../../components/Admin/TeamList';
import Times from '../../components/Admin/Times';
import useAxios from '../../services/UseAxios';

const AdminDashboardView = () => {
  let { gameId } = useParams();

  const [game, setGame] = useState();
  const { getRequest } = useAxios();

  const fetchGame = async () => {
    await getRequest(`/admin/games/${gameId}`)
      .then((result) => {
        setGame(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchGame();
  }, []);

  if (!game) {
    return null;
  }

  return (
    <div>
      <GameHeader game={game} className="mb-3" />
      <Times game={game} setGame={setGame} />
      <Button
        style={{ width: '400px', display: 'block', margin: '20px auto', fontSize: '48px' }}
        className="btn btn-success"
        tag={Link}
        to={`/admin/game/${game.id}/play`}
      >
        Spelen!
      </Button>
      <TeamsList game={game} />
      <Button className="mt-5 btn btn-info" tag={Link} to="/admin">
        <FontAwesomeIcon icon="chevron-left" className="mr-3" />
        Ga terug
      </Button>
    </div>
  );
};

export default AdminDashboardView;
