import React, { useEffect, useState } from 'react';
import useAxios from '../../services/UseAxios';
import { FormatDate } from '../../services/DateHelper';
import { useHistory } from 'react-router';

const AdminView = () => {
  const [games, setGames] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getRequest } = useAxios();
  const history = useHistory();

  const fetchGames = async () => {
    await getRequest('/admin/games')
      .then((result) => {
        setGames(result.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGames();
  }, []);

  if (loading) {
    return <div className="admin">Loading...</div>;
  }

  return (
    <div className="admin">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Starts</th>
            <th>Ends</th>
            <th># Teams</th>
          </tr>
        </thead>
        <tbody>
          {games.map((game) => {
            const numTeams = game.teams.length;

            return (
              <tr key={game.id} onClick={() => history.push(`/admin/game/${game.id}`)} className="is-clickable">
                <td>
                  <FormatDate date={game.starts} />
                </td>
                <td>
                  <FormatDate date={game.ends} />
                </td>
                <td>{numTeams}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminView;
