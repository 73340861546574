import React from 'react';

const InstructionsView = () => {
  return (
    <div className="orbi instructions">
      <h1 className="orbi mt-5">Speluitleg</h1>
      <h2 className="orbi mt-5">Doel van het spel</h2>
      <p>
        Ontmantel de bom binnen drie uur. Ontgrendel de 5 symbolen door de onderliggende puzzels op te losssen. Vervolgens kun je de bom
        onschadelijk maken.
      </p>
      <h2 className="orbi mt-5">Hoe werkt het ontstekingsmechanisme?</h2>
      <img src={`/images/speluitleg.png`} />
      <h2 className="orbi mt-5">Tips bij het oplossen van de puzzels</h2>
      <p>
        Gebruik het ontstekingsmechanimse als leidraad voor welke combinatie ingevoerd moet worden en ga vervolgens op zoek naar informatie.
        Hierbij enkele tips:{' '}
      </p>
      <ul>
        <li>Kijk in de krant voor aanwijzingen.</li>
        <li>Kijk of er andere attributen in de doos zitten waarmee je een puzzel kunt kraken.</li>
        <li>Soms moet je in het centrum van Heeze op zoek naar aanwijzingen. Hierbij heb je bijna altijd een van de attributen nodig.</li>
        <li>Elk attribuut (behalve de krant) heb je slechts 1x nodig.</li>
      </ul>
      <h2 className="orbi mt-5">Kijk de introductiefilm terug</h2>
      <video autoPlay={false} controls={true} muted={false} poster="/images/video-poster-trailer.jpg">
        <source src="https://cdn.dewraakvanlind.nl/videos/introduction.mp4" type="video/mp4" />
        <track label="Nederlands" kind="subtitles" srcLang="nl" src="/subtitles/introduction.vtt" default></track>
      </video>
    </div>
  );
};

export default InstructionsView;
