import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useAxios from '../../services/UseAxios';
import { FormatDate, FormatDuration } from '../../services/DateHelper';
import RevengeContext from '../../services/RevengeContext';
import Chat from '../../components/Chat';
import DateCountdown from '../../components/Countdown/DateCountdown';
import { SendNotification } from '../../services/NotificationHelper';
import Button from 'reactstrap/lib/Button';

const AdminPlayView = () => {
  const params = useParams();
  const gameIdParam = params.gameId;

  const [adminDashboardTeams, setAdminDashboardTeams] = useState(null);
  const adminDashboardTeamsRef = useRef();
  adminDashboardTeamsRef.current = adminDashboardTeams;

  const [chatSnippets, setChatSnippets] = useState();
  const [chatSnippetsLoading, setChatSnippetsLoading] = useState(true);

  const chatsSeenString = localStorage.getItem('chatsSeen') ?? '{}';
  const [chatsSeen, setChatsSeen] = useState(JSON.parse(chatsSeenString));

  const [teamsLoading, setTeamsLoading] = useState(true);
  const [team, setTeam] = useState(null);
  const [chatTitle, setChatTitle] = useState();

  const [lock, setLock] = useState(null);
  const lockRef = useRef();
  lockRef.current = lock;

  const [currentPages, setCurrentPages] = useState({});

  const [canChat, setCanChat] = useState(false);
  const mountedRef = useRef(true);

  const [sortByLeaderboard, setSortByLeaderboard] = useState(false);
  const [showChatIcons, setShowChatIcons] = useState(true);

  const { teamId, setTeamId, teamIdRef, puzzleLock, setPuzzleLock, journalEvents, chatEvents, adminEvents, gameContext, fetchGameContext } =
    useContext(RevengeContext);
  const { getRequest, postRequest, gameId, setGameId } = useAxios();

  const hasSeenChat = (teamId, chatId, puzzleCode, lockSequence) => {
    if (!chatId) {
      return true;
    }

    if (!chatsSeen) {
      return false;
    }

    const key = puzzleCode ? `${teamId}-${puzzleCode}-${lockSequence}` : `${teamId}-general`;
    let latestChatIdSeen = chatsSeen[key];

    if (!latestChatIdSeen) {
      return false;
    }

    latestChatIdSeen = parseInt(latestChatIdSeen);
    return latestChatIdSeen === chatId;
  };

  useEffect(() => {
    setChatTitle(null);

    if (!teamId) {
      setTeam(null);
      setLock(null);
      setCanChat(false);
      return;
    }

    const newTeam = adminDashboardTeams.teams.filter((x) => x.id === teamIdRef.current)[0];
    setTeam(newTeam);

    if (puzzleLock) {
      const newLock =
        newTeam.locks.filter((x) => x.puzzleCode === puzzleLock.puzzleCode && x.lockSequence === puzzleLock.lockSequence)[0] || null;
      setLock(newLock);

      setCanChat(newLock !== null);

      const lock = adminDashboardTeams.locks.filter(
        (x) => x.puzzleCode === puzzleLock.puzzleCode && x.sequence === puzzleLock.lockSequence
      )[0];
      setChatTitle(` (${lock?.answer}) - ${newTeam.name}`);

      fetchChatSnippets(teamId, puzzleLock);
    } else {
      setLock(null);
      setCanChat(true);
      setChatSnippets(null);
    }

    return () => {};
  }, [adminDashboardTeams, teamId, puzzleLock]);

  const fetchAdminDashboardData = async () => {
    await getRequest(`/admin/teams/${gameIdParam}/play`)
      .then((result) => {
        setAdminDashboardTeams(result.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTeamsLoading && setTeamsLoading(false);
      });
  };

  const fetchChatSnippets = async (teamId, puzzleLock) => {
    await getRequest(`/admin/chat-snippets/${teamId}/${puzzleLock.puzzleCode}/${puzzleLock.lockSequence}`)
      .then((result) => {
        setChatSnippets(result.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setChatSnippetsLoading(false);
      });
  };

  const sendChatSnippet = async (teamId, snippetId) => {
    if (!window.confirm('Voorgestelde hint versturen?')) {
      return;
    }

    await postRequest(`/admin/chat-snippets/${teamId}/${snippetId}`)
      .then((result) => {
        setChatSnippets(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setGameId(gameIdParam);
    fetchAdminDashboardData();

    const journalSub = journalEvents.subscribe({
      next: (data) => {
        if (data || !mountedRef.current) {
          return null;
        }

        fetchAdminDashboardData();
      },
    });

    const chatSub = chatEvents.subscribe({
      next: (data) => {
        if (data.fromAdmin || !mountedRef.current) {
          return;
        }

        if (
          teamIdRef.current === data.teamId &&
          lockRef.current &&
          lockRef.current.puzzleCode === data.puzzleCode &&
          lockRef.current.lockSequence === data.lockSequence
        ) {
          // Already on the right chat
          return;
        }

        SendNotification(`${data.teamName || 'Een team'} heeft een vraag`, { body: data.message }, () => {
          setPuzzleLock({
            puzzleCode: data.puzzleCode,
            lockSequence: data.lockSequence,
          });
          setTeamId(data.teamId);
        });
      },
    });

    const adminSub = adminEvents.subscribe({
      next: (data) => {
        const key = data.puzzleCode ? `${data.puzzleCode}.${data.lockSequence}` : 'general';
        currentPages[data.teamId] = key;
        setCurrentPages({ ...currentPages });
      },
    });

    return () => {
      journalSub.dispose();
      chatSub.dispose();
      adminSub.dispose();
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!gameId) {
      return;
    }
    if (!gameContext || gameContext.game.id !== gameId) {
      fetchGameContext();
    }
  }, [gameId]);

  if (teamsLoading) {
    return null;
  }

  if (!adminDashboardTeams) {
    return null;
  }

  let effectiveTeams = [...adminDashboardTeams.teams];

  if (sortByLeaderboard) {
    effectiveTeams.sort((a, b) => a.sortOrderLeaderboard - b.sortOrderLeaderboard);
  } else {
    effectiveTeams.sort((a, b) => a.sortOrderCreated - b.sortOrderCreated);
  }

  return (
    <div className="admin-dashboard">
      <div className="row" style={{ minHeight: '600px' }}>
        <div className="col-8">
          <div className="bomb-ticker mb-3">
            <DateCountdown />
          </div>

          <table className="table table-sm table-striped admin-dashboard table-dashboard">
            <thead>
              <tr>
                <th>#</th>
                <th>Team</th>
                <th>Code</th>
                {adminDashboardTeams.puzzles?.map((puzzle) => {
                  const locks = adminDashboardTeams.locks.filter((x) => x.puzzleCode === puzzle.code);

                  return locks.map((lock) => {
                    return (
                      <th key={`${puzzle.code}-${lock.sequence}`} className={`lock lock-${puzzle.code}`} title={lock.answer}>
                        <span>
                          {puzzle.code === 'BOMB' && <FontAwesomeIcon icon="bomb" size="1x" className="bomb" />}
                          {puzzle.code !== 'BOMB' && (
                            <>
                              <img src={`/images/puzzles/${puzzle.code}w.svg`} width="12" height="12" /> {lock.sequence}
                            </>
                          )}
                          &nbsp;&nbsp;
                          {lock.answer}
                        </span>
                      </th>
                    );
                  });
                })}
                <th style={{ textAlign: 'center' }}>
                  <span>Wrong answers</span>
                </th>
                <th style={{ textAlign: 'center' }}>
                  <span>Correct answers</span>
                </th>
                <th style={{ textAlign: 'center' }}>
                  <span>Tijd over</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {effectiveTeams?.map((team) => {
                let chatClass = !showChatIcons || hasSeenChat(team.id, team.chatId, null, null) ? '' : 'unread-chat';
                let pageClass = currentPages[team.id] && currentPages[team.id] === 'general' ? 'current-page' : '';
                const activatedClass = team.activated ? 'activated' : 'not-activated';
                const effectiveTeamName = team.activated ? team.name : team.contactName;
                const currentTeamClass = teamId && team.id.toUpperCase() === teamId.toUpperCase() ? 'current-team' : '';

                return (
                  <tr key={team.id} className={currentTeamClass}>
                    <td>{team.sortOrderCreated}</td>
                    <td
                      className={`team-name ${chatClass} ${pageClass} ${activatedClass}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setPuzzleLock(null);
                        setTeamId(team.id);
                      }}
                      title={team.contactName}
                    >
                      {effectiveTeamName}
                    </td>
                    <td style={{ textAlign: 'left' }}>{team.code}</td>
                    {adminDashboardTeams?.puzzles?.map((puzzle) => {
                      const locks = adminDashboardTeams.locks.filter((x) => x.puzzleCode === puzzle.code);

                      return locks.map((lock) => {
                        let answer = <span>--</span>;
                        let selectedClass =
                          teamId &&
                          team &&
                          teamId === team.id &&
                          puzzleLock &&
                          puzzleLock.puzzleCode === puzzle.code &&
                          puzzleLock.lockSequence === lock.sequence
                            ? 'lock-selected'
                            : '';

                        chatClass = '';
                        pageClass =
                          currentPages[team.id] && currentPages[team.id] === `${puzzle.code}.${lock.sequence}` ? 'current-page' : '';

                        const teamLock = team?.locks.filter((x) => x.puzzleCode === lock.puzzleCode && x.lockSequence === lock.sequence)[0];

                        if (teamLock) {
                          if (showChatIcons && teamLock.chatId && !hasSeenChat(team.id, teamLock.chatId, lock.puzzleCode, lock.sequence)) {
                            chatClass = 'unread-chat';
                            answer = <FontAwesomeIcon icon="comments" className="fa-spin" />;
                          } else if (teamLock.completed) {
                            answer = <span title={teamLock.completed}>✅</span>;
                          } else {
                            answer = (
                              <span title={lock.answer}>
                                <FontAwesomeIcon icon="hourglass-half" />
                              </span>
                            );
                          }
                        }

                        return (
                          <td
                            key={`${puzzle.code}-${lock.sequence}`}
                            style={{ cursor: 'pointer' }}
                            className={`lock lock-${puzzle.code} ${selectedClass} ${chatClass} ${pageClass}`}
                            onClick={() => {
                              setPuzzleLock({
                                puzzleCode: puzzle.code,
                                lockSequence: lock.sequence,
                              });
                              setTeamId(team.id);
                            }}
                          >
                            {answer}
                          </td>
                        );
                      });
                    })}
                    <td>{team.wrongAnswers}</td>
                    <td>{team.locksCompleted}</td>
                    <td>
                      {team.timeRemaining && <>{FormatDuration(team.timeRemaining)}</>}
                      {!team.timeRemaining && <FontAwesomeIcon icon="hourglass-half" />}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {!sortByLeaderboard && (
            <button className="mt-3 btn btn-warning" onClick={() => setSortByLeaderboard(true)}>
              Sort by leaderboard
            </button>
          )}
          {sortByLeaderboard && (
            <button className="mt-3 btn btn-info" onClick={() => setSortByLeaderboard(false)}>
              Sort by date created
            </button>
          )}

          {showChatIcons && (
            <button className="mt-3 ml-3 btn btn-info" onClick={() => setShowChatIcons(false)}>
              Hide chat icons
            </button>
          )}
          {!showChatIcons && (
            <button className="mt-3 ml-3 btn btn-warning" onClick={() => setShowChatIcons(true)}>
              Show chat icons
            </button>
          )}
        </div>
        <div className="col-4">
          {team && canChat && (
            <>
              <div className="chat-container">
                <Chat title={chatTitle} chatsSeen={chatsSeen} setChatsSeen={setChatsSeen} />
              </div>

              {!chatSnippetsLoading && chatSnippets && (
                <div className="chat-snippets">
                  <h6>Voorgestelde hints</h6>

                  <table className="table table-sm table-striped">
                    <tbody>
                      {chatSnippets.map((snippet) => {
                        let onClick = null;
                        let className = null;
                        var alreadySent = snippet.alreadySent.filter((x) => x.snippetId === snippet.id)[0];
                        if (alreadySent) {
                          className = 'already-sent';
                        } else {
                          onClick = () => {
                            sendChatSnippet(teamId, snippet.id);
                          };
                        }

                        return (
                          <tr key={snippet.id}>
                            <td disabled={alreadySent} className={className} onClick={onClick}>
                              {snippet.message}
                            </td>
                            <td className={`snippet-sent ${className}`}>
                              {alreadySent && <FormatDate date={alreadySent.date} showToday={false} />}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Button type="submit" className="mt-5 btn btn-info" tag={Link} to={`/admin/game/${gameIdParam}`}>
        <FontAwesomeIcon icon="chevron-left" className="mr-3" />
        Ga terug
      </Button>
    </div>
  );
};

export default AdminPlayView;
