import React, { useEffect, useRef, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import * as signalR from '@microsoft/signalr';
import initFontAwesome from './utils/initFontAwesome';
import './App.scss';
import RevengeContext from './services/RevengeContext';
import Loading from './components/Loading';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import HomeView from './views/HomeView';
import history from './utils/history';
import PrivacyView from './views/PrivacyView';
import TeamDashboardView from './views/Team/TeamDashboardView';
import NotFoundView from './views/NotFoundView';
import TeamJoinView from './views/Team/TeamJoinView';
import useGameContext from './services/GameContextService';
import AdminDashboardView from './views/Admin/AdminDashboardView';
import AdminView from './views/Admin/AdminView';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import GameContainer from './views/Game/GameContainer';
import GameBombExploded from './views/Game/GameBombExploded';
import GameBombDefused from './views/Game/GameBombDefused';
import WaitingRoom from './views/WaitingRoom';
import GameIntroduction from './views/Game/GameIntroduction';
import ErrorCard from './components/Cards/ErrorCard';
import { RequestNotificationPermissions } from './services/NotificationHelper';
import InstructionsView from './views/InstructionsView';
import GameCompleted from './views/Game/GameCompleted';
import LeaderboardView from './views/Game/LeaderboardView';
import Chat from './components/Chat';
import { getDateOffset } from './services/DateHelper';
import CreditsView from './views/Credits';
import AdminTeamEditView from './views/Admin/AdminTeamEditView';
import AdminPlayView from './views/Admin/AdminPlayView';

initFontAwesome();

const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      'InstrumentationKey=73e42914-513c-46c0-aeb5-6f38f847b281;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/',
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const App = () => {
  const [hubConnection, setHubConnection] = useState(null);
  const [dateOffset, setDateOffset] = useState(null);
  const dateOffsetRef = useRef();
  dateOffsetRef.current = dateOffset;

  const [journalEvents, setJournalEvents] = useState(new signalR.Subject());
  const [chatEvents, setChatEvents] = useState(new signalR.Subject());
  const [adminEvents, setAdminEvents] = useState(new signalR.Subject());
  const [introChatsSeen, setIntroChatsSeen] = useState([]);
  const [chatsUnseen, setChatsUnseen] = useState([]);

  const { gameContext, setGameContext, gameContextLoading, gameContextError, fetchGameContext } = useGameContext(setDateOffset);
  const gameContextRef = useRef();
  gameContextRef.current = gameContext;

  const [puzzleCode, setPuzzleCode] = useState(null);
  const [puzzleLock, setPuzzleLock] = useState(null);

  const [teamId, setTeamId] = useState();
  const teamIdRef = useRef();
  teamIdRef.current = teamId;

  const [showBombBackground, setShowBombBackground] = useState(false);
  const [sounds, setSounds] = useState(null);

  const initHub = () => {
    const hub = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_ENDPOINT + '/gameHub')
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    hub.onclose((err) => {
      if (err) {
        console.log('hub error');
        console.log(err);
      } else {
        console.log('hub complete');
      }

      setHubConnection(null);
      initHub();
    });

    const afterConnect = () => {
      const token = localStorage.getItem('teamCode');
      hub.invoke('ConnectionStarted', gameContext.team.id, token);
      setTimeout(() => ping(hub), 10000);
    };

    hub
      .start()
      .then(() => {
        console.log('Connection started: ' + hub.connectionId);
        afterConnect();
      })
      .catch((err) => {
        console.log('Error while starting connection: ', err);
        initHub();
        return;
      });

    hub.onreconnected(() => {
      console.log('Connection reconnected: ' + hub.connectionId);
      afterConnect();
    });

    hub.on('Pong', (data) => {
      pong(hub, data);
    });

    hub.on('Context', (data) => {
      var newContext = { ...gameContextRef.current, ...data };
      setGameContext(newContext);
    });

    hub.on('Times', (data) => {
      if (!gameContextRef.current.game || gameContextRef.current.game.id !== data.gameId) {
        return;
      }

      var newContext = { ...gameContextRef.current };
      newContext.game.starts = data.starts;
      newContext.game.started = data.started;
      newContext.game.ends = data.ends;
      newContext.game.ended = data.ended;

      setGameContext(newContext);
    });

    hub.on('Chat', (data) => {
      chatEvents.next(data);
    });

    hub.on('Journal', (data) => {
      journalEvents.next(data);
    });

    hub.on('changed-page', (teamId, puzzleCode, lockSequence) => {
      const data = {
        name: 'changed-page',
        teamId: teamId,
        puzzleCode: puzzleCode,
        lockSequence: lockSequence,
      };
      adminEvents.next(data);
    });

    setHubConnection(hub);
  };

  const getAudio = (url, volume = 0.2) => {
    const result = new Audio(url);
    result.volume = volume;
    return result;
  };

  useEffect(() => {
    setSounds({
      correct: getAudio('https://cdn.dewraakvanlind.nl/sounds/correct-answer.mp3'),
      wrong: getAudio('https://cdn.dewraakvanlind.nl/sounds/wrong-answer.mp3', 0.05),
      buttonPress: getAudio('https://cdn.dewraakvanlind.nl/sounds/button-pressed.mp3'),
      lockNotAvailable: getAudio('https://cdn.dewraakvanlind.nl/sounds/lock-not-available.mp3'),
      chatJop: getAudio('https://cdn.dewraakvanlind.nl/sounds/chat-from-job.mp3'),
      chatText: getAudio('https://cdn.dewraakvanlind.nl/sounds/chat-text-appearing.mp3', 0.1),
    });

    RequestNotificationPermissions();
  }, []);

  useEffect(() => {
    if (!gameContext?.team || hubConnection) {
      return;
    }

    initHub();
  }, [gameContext]);

  useEffect(() => {
    if (!gameContext?.team) {
      return;
    }

    if (!gameContext?.team?.admin) {
      setTeamId(gameContext.team.id);
    }
  }, [gameContext?.team]);

  let pingTimeout;

  const ping = (hubConnection) => {
    if (hubConnection?.connectionState !== 'Connected') {
      return;
    }

    // console.log('%c Ping ', 'background-color: white; color: blue');
    hubConnection.invoke('Ping');
    pingTimeout = setTimeout(() => {
      console.log('Ping timed out, restarting hub');
      hubConnection.stop().catch((err) => {
        console.log('Error during hubConnection stop: ', err);
      });
    }, 5000);
  };

  const pong = (hubConnection, data) => {
    // console.log('%c Pong ', 'background-color: white; color: green');
    clearTimeout(pingTimeout);
    setTimeout(() => ping(hubConnection), 10000);
    setDateOffset(getDateOffset(data));
  };

  if (gameContextError) {
    return (
      <div className="container-fluid mt-3">
        <ErrorCard header="Fout - probeer aub opnieuw" title="Game kan niet geladen worden" message={`${gameContextError.message}`} />
      </div>
    );
  }

  if (gameContextLoading) {
    return <Loading />;
  }

  return (
    <RevengeContext.Provider
      value={{
        gameContext,
        setGameContext,
        gameContextRef,
        fetchGameContext,
        journalEvents,
        chatEvents,
        adminEvents,
        sounds,
        teamId,
        setTeamId,
        teamIdRef,
        puzzleCode,
        setPuzzleCode,
        puzzleLock,
        setPuzzleLock,
        setShowBombBackground,
        dateOffsetRef,
        setDateOffset,
      }}
    >
      <Router history={history}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <div
            className={`mt-3 ${
              showBombBackground
                ? gameContext.game.summary.canDefuseBomb && puzzleCode === 'BOMB'
                  ? 'bomb-panel bomb-panel-open'
                  : 'bomb-panel'
                : window.location.pathname.startsWith('/admin')
                ? 'flex-grow-1 container-fluid'
                : `flex-grow-1 container`
            }`}
          >
            <div>
              <Switch>
                <Route path="/" exact render={() => <HomeView />} />
                <AdminRoute path="/admin" exact render={() => <AdminView />} />
                <AdminRoute path="/admin/game/:gameId/teams/:teamId" exact render={() => <AdminTeamEditView />} />
                <AdminRoute path="/admin/game/:gameId/play" exact render={() => <AdminPlayView />} />
                <AdminRoute path="/admin/game/:gameId" exact render={() => <AdminDashboardView />} />
                <Route path="/team/join/:code?" exact render={() => <TeamJoinView />} />
                <Route path="/team" exact render={() => <TeamDashboardView key={`team-${window.location.href}`} />} />
                <Route path="/privacy" exact render={() => <PrivacyView />} />
                <Route path="/instructions" exact render={() => <InstructionsView />} />
                <Route path="/credits" exact render={() => <CreditsView />} />
                {gameContext.team && gameContext.game?.started && (
                  <Switch>
                    <ProtectedRoute path="/game/leaderboard" exact render={() => <LeaderboardView />} />
                    <ProtectedRoute path="/game/introduction" exact render={() => <GameIntroduction />} />
                    <ProtectedRoute path="/game/bomb-exploded" exact render={() => <GameBombExploded />} />
                    <ProtectedRoute path="/game/bomb-defused" exact render={() => <GameBombDefused />} />
                    <ProtectedRoute path="/game/completed" exact render={() => <GameCompleted />} />
                    <ProtectedRoute path="/game" render={() => <GameContainer />} />
                  </Switch>
                )}
                {!gameContext.game?.started && <ProtectedRoute path="/game" exact render={() => <WaitingRoom />} />}
                <Route path="/" render={() => <NotFoundView />} />
              </Switch>
            </div>
            {showBombBackground && !gameContext.team?.admin && (
              <div className="chat-container">
                <Chat
                  introChatsSeen={introChatsSeen}
                  setIntroChatsSeen={setIntroChatsSeen}
                  chatsUnseen={chatsUnseen}
                  setChatsUnseen={setChatsUnseen}
                />
              </div>
            )}
          </div>
          <Footer />
        </div>
      </Router>
    </RevengeContext.Provider>
  );
};

export default App;
