import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isTouchDevice } from '../../services/MobileHelper';
import RevengeContext from '../../services/RevengeContext';
import useAxios from '../../services/UseAxios';

const GameLockQuestion = ({ puzzle, lock }) => {
  const [guess, setGuess] = useState('');
  const [wrongClassName, setWrongClassName] = useState('');
  const [saving, setSaving] = useState(false);
  const [placeholder, setPlaceholder] = useState('Vul je antwoord in');
  const { postRequest } = useAxios();
  const inputRef = useRef();

  const { gameContext, setGameContext, sounds } = useContext(RevengeContext);

  useEffect(() => {
    !isTouchDevice() && inputRef.current.focus();

    return () => {};
  }, []);

  const GuessRequest = async (e) => {
    e.preventDefault();

    if (saving) {
      return;
    }

    setSaving(true);

    await postRequest('/lock/guess', {
      puzzleCode: puzzle.code,
      lockSequence: lock.sequence,
      answer: guess,
    })
      .then((result) => {
        if (result.data.correct === false) {
          setWrongClassName('wrong');
          setPlaceholder('Fout! Probeer maar opnieuw.');
          setGuess('');

          sounds.wrong.play();
        } else {
          sounds.correct.play();
        }

        if (result.data.context) {
          setGameContext(result.data.context);
        }
      })
      .catch((err) => {
        console.log(err);
        setWrongClassName('wrong');
        setPlaceholder('System error, probeer aub opnieuw');
        setGuess('');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <form className="form" onSubmit={GuessRequest}>
      <input
        type="text"
        className={`form-control form-control__lock ${wrongClassName} ${saving ? 'loading' : ''}`}
        placeholder={placeholder}
        autoFocus={!isTouchDevice()}
        required={true}
        disabled={saving}
        value={guess}
        onChange={(e) => setGuess(e.target.value)}
        onAnimationEnd={() => {
          setWrongClassName('');
          !isTouchDevice() && inputRef.current.focus();
        }}
        ref={inputRef}
      />

      <button type="submit" className="btn btn-primary submit-icon" disabled={saving}>
        <FontAwesomeIcon icon="paper-plane" />
      </button>
    </form>
  );
};

export default GameLockQuestion;
