import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import RevengeContext from '../../services/RevengeContext';

const GameLockClue = () => {
  const { puzzleLock } = useContext(RevengeContext);
  const questionRef = useRef();

  useEffect(() => {
    questionRef.current && questionRef.current.scrollTo(0, 0);
  }, [puzzleLock]);

  if (!puzzleLock) {
    return null;
  }

  const { puzzleCode, lockSequence } = puzzleLock;

  return (
    <div ref={questionRef} className={`clue clue-${puzzleLock.puzzleCode}`}>
      {puzzleCode !== 'BOMB' && (
        <div className="clue-heading badge badge-dark mb-1">
          Slot <img src={`/images/puzzles/${puzzleCode}w.svg`} width="3%" height="3%" />
          {lockSequence}
        </div>
      )}

      {puzzleCode === 'A1' && (
        <>
          {lockSequence === 1 && <div>Code van drie letters</div>}
          {lockSequence === 2 && (
            <div>
              In de tuin waar het grote feest begint
              <br />
              Waar het bier rijkelijk vloeit in dorstige kelen
              <br />
              Waar de muziek vriendenkring met vriendenkring verbindt
              <br />
              Als de zon hoog aan de hemel staat, maar ook als het giet met pijpenstelen
              <br />
              <br />
              Doet een stenen teken ons herinneren aan een paarl’en jubeljaar
              <br />
              Met daarin gegraveerd twee namen van voorname heren
              <br />
              Zoek en gij zult vinden aldaar
              <br />
              Het antwoord om u door dit labyrint heen te manoeuvreren
              <hr />
              Twee namen (xxx en xxx)
            </div>
          )}
          {lockSequence === 3 && (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>A</td>
                    <td>
                      <b>&bull; —</b>
                    </td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>
                      <b>— &bull; &bull; &bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td>
                      <b>— &bull; — &bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>D</td>
                    <td>
                      <b>— &bull; &bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>E</td>
                    <td>
                      <b>&bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>F</td>
                    <td>
                      <b>&bull; &bull; — &bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>G</td>
                    <td>
                      <b>— — &bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>H</td>
                    <td>
                      <b>&bull; &bull; &bull; &bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>I</td>
                    <td>
                      <b>&bull; &bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>J</td>
                    <td>
                      <b>&bull; — — —</b>
                    </td>
                  </tr>
                  <tr>
                    <td>K</td>
                    <td>
                      <b>— &bull; —</b>
                    </td>
                  </tr>
                  <tr>
                    <td>L</td>
                    <td>
                      <b>&bull; — &bull; &bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>M</td>
                    <td>
                      <b>— —</b>
                    </td>
                  </tr>

                  <tr>
                    <td>N</td>
                    <td>
                      <b>— &bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>O</td>
                    <td>
                      <b>— — —</b>
                    </td>
                  </tr>
                  <tr>
                    <td>P</td>
                    <td>
                      <b>&bull; — — &bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Q</td>
                    <td>
                      <b>— — &bull; —</b>
                    </td>
                  </tr>
                  <tr>
                    <td>R</td>
                    <td>
                      <b>&bull; — &bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>S</td>
                    <td>
                      <b>&bull; &bull; &bull;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>T</td>
                    <td>
                      <b>—</b>
                    </td>
                  </tr>
                  <tr>
                    <td>U</td>
                    <td>
                      <b>&bull; &bull; —</b>
                    </td>
                  </tr>
                  <tr>
                    <td>V</td>
                    <td>
                      <b>&bull; &bull; &bull; —</b>
                    </td>
                  </tr>
                  <tr>
                    <td>W</td>
                    <td>
                      <b>&bull; — —</b>
                    </td>
                  </tr>
                  <tr>
                    <td>X</td>
                    <td>
                      <b>— &bull; &bull; —</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Y</td>
                    <td>
                      <b>— &bull; — —</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Z</td>
                    <td>
                      <b>— — &bull; &bull;</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <p>Een woord</p>
            </div>
          )}
        </>
      )}
      {puzzleCode === 'A2' && (
        <>
          {lockSequence === 1 && <div>Code van vier cijfers</div>}
          {lockSequence === 2 && (
            <div>
              <p>
                Stenen plakkaten sieren het wegdek van de Heezer straten <br />
                Relikwieën van triomfen van formaat <br />
                Memoreren de dorpelingen aan in het verleden behaalde resultaten <br />
                Zodat de herinnering nooit meer verloren gaat <br />
                <br />
                Alle tegels op een nette rij vormen een lofzang op mateloze creativiteit <br />
                Maar één van de plakkaten ligt ondersteboven in deze keurige serie
                <br />
                Speur naar deze vreemde eend in de bijt <br />
                En vind daar een bijbehorend huisnummer als antwoord op dit mysterie
                <br />
              </p>
              <hr />
              <p>Huisnummer</p>
            </div>
          )}
          {lockSequence === 3 && (
            <div>
              <p>M = 32</p>

              <table className="table">
                <tbody>
                  <tr>
                    <th></th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                  </tr>
                  <tr>
                    <th>1</th>
                    <td>A</td>
                    <td>B</td>
                    <td>C</td>
                    <td>D</td>
                    <td>E</td>
                  </tr>
                  <tr>
                    <th>2</th>
                    <td>F</td>
                    <td>G</td>
                    <td>H</td>
                    <td>I/J</td>
                    <td>K</td>
                  </tr>
                  <tr>
                    <th>3</th>
                    <td>L</td>
                    <td>M</td>
                    <td>N</td>
                    <td>O</td>
                    <td>P</td>
                  </tr>
                  <tr>
                    <th>4</th>
                    <td>Q</td>
                    <td>R</td>
                    <td>S</td>
                    <td>T</td>
                    <td>U</td>
                  </tr>
                  <tr>
                    <th>5</th>
                    <td>V</td>
                    <td>W</td>
                    <td>X</td>
                    <td>Y</td>
                    <td>Z</td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <p>Code van vier letters</p>
            </div>
          )}
        </>
      )}
      {puzzleCode === 'A3' && (
        <>
          {lockSequence === 1 && <div>Vier woorden:</div>}
          {lockSequence === 2 && (
            <div>
              Noeste arbeiders op een zuilvoet
              <br />
              Een eerbetoon aan gouden handen voor jarenlange werklust
              <br />
              Hun aandeel is van groot belang voor de historische stoet
              <br />
              Zij hebben de blaren op hun handen geklust
              <br />
              <br />
              Hun namen vereeuwigd bij dit monument
              <br />
              Verschaffen u daar de sleutels voor een rekenkundige vraag
              <br />
              De jaartallen maken zich op die plek aan u bekend
              <br />
              Haast u, want de tijd vordert gestaag...
              <hr />
              Code van vier cijfers:
            </div>
          )}
          {lockSequence === 3 && (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>Q</td>
                    <td>
                      <FontAwesomeIcon icon="hand-point-up" />
                    </td>
                  </tr>
                  <tr>
                    <td>R</td>
                    <td>
                      <FontAwesomeIcon icon="hand-point-left" />
                    </td>
                  </tr>
                  <tr>
                    <td>S</td>
                    <td>
                      <FontAwesomeIcon icon="hand-paper" />
                    </td>
                  </tr>
                  <tr>
                    <td>T</td>
                    <td>
                      <FontAwesomeIcon icon="hand-peace" />
                    </td>
                  </tr>
                  <tr>
                    <td>U</td>
                    <td>
                      <FontAwesomeIcon icon="hand-point-down" />
                    </td>
                  </tr>
                  <tr>
                    <td>V</td>
                    <td>
                      <FontAwesomeIcon icon="praying-hands" />
                    </td>
                  </tr>
                  <tr>
                    <td>W</td>
                    <td>
                      <FontAwesomeIcon icon="hand-rock" />
                    </td>
                  </tr>
                  <tr>
                    <td>X</td>
                    <td>
                      <FontAwesomeIcon icon="hand-lizard" />
                    </td>
                  </tr>
                  <tr>
                    <td>Y</td>
                    <td>
                      <FontAwesomeIcon icon="hand-point-right" />
                    </td>
                  </tr>
                  <tr>
                    <td>Z</td>
                    <td>
                      <FontAwesomeIcon icon="thumbs-up" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              Code van drie letters:
            </div>
          )}
        </>
      )}
      {puzzleCode === 'A4' && (
        <>
          {lockSequence === 1 && (
            <div>
              <p>Code van drie letters</p>
            </div>
          )}
          {lockSequence === 2 && (
            <div>
              Midden in het bruisend hart van Heeze waar de rollende decors voorbij trekken
              <br />
              Wijst een aandenken op een gouden verjaardagsfeest
              <br />
              Daar is een verhaal van een groot Neerlands schrijfster te ontdekken
              <br />
              Maar zonder hulpmiddel begrijpt u niets van wat u leest
              <br />
              <br />
              Spoed u zich naar dit meesterwerk en laat de woorden de aanwijzing geven
              <br />
              Zodat u het volgende raadsel ontcijferd krijgt
              <br />
              Zonder dit antwoord zal de Brabantsedag niet overleven
              <br />
              Maakt voort, want het naderende einde dreigt
              <hr />
              Code van zeven cijfers
            </div>
          )}
          {lockSequence === 3 && (
            <div>
              Schurken, Schelmen en Schorremorrie = 6<br />
              Vreemd! = 5<br />
              <hr />
              Bekend, Beroemd, Bemind = ?
              <br />
              Vol van Water = ?
              <br />
              Eeuw na Eeuw = ?
              <hr />
              Twee woorden
            </div>
          )}
        </>
      )}
      {puzzleCode === 'A5' && (
        <>
          {lockSequence === 1 && (
            <div>
              <p>Jaartal</p>
            </div>
          )}
          {lockSequence === 2 && (
            <div>
              <p>Code van vier cijfers</p>
            </div>
          )}
        </>
      )}
      {puzzleCode === 'BOMB' && <div></div>}
    </div>
  );
};

export default GameLockClue;
