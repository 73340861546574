import React from 'react';
import { FormatDate } from '../../services/DateHelper';

const GameHeader = ({ game, className }) => {
  if (!game) {
    return null;
  }

  return (
    <h2 className={className}>
      Game: <FormatDate date={game.starts} />
    </h2>
  );
};

export default GameHeader;
