import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import useAxios from '../services/UseAxios';
import { FormatDuration } from '../services/DateHelper';
import RevengeContext from '../services/RevengeContext';

const Leaderboard = () => {
  const { journalEvents } = useContext(RevengeContext);

  const [teams, setTeams] = useState();
  const [teamsLoading, setTeamsLoading] = useState(true);
  const { getRequest } = useAxios();
  const mountedRef = useRef(true);

  const fetchData = async () => {
    await getRequest('/leaderboard')
      .then((result) => {
        setTeams(result.data);
      })
      .finally(() => {
        setTeamsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();

    const sub = journalEvents.subscribe({
      next: (data) => {
        if (data || !mountedRef.current) {
          return null;
        }

        fetchData();
      },
    });

    return () => {
      sub.dispose();
      mountedRef.current = false;
    };
  }, []);

  if (teamsLoading) {
    return null;
  }

  return (
    <table className="table table-striped table-dashboard small-images">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          {teams?.puzzles?.map((puzzle) => (
            <th key={puzzle.code}>
              {puzzle.code === 'BOMB' && <FontAwesomeIcon icon="bomb" size="1x" className="bomb" />}
              {puzzle.code !== 'BOMB' && <img src={`/images/puzzles/${puzzle.code}.png`} />}
            </th>
          ))}
          <th>Verkeerde antwoorden</th>
          <th>Tijd over</th>
        </tr>
      </thead>
      <tbody>
        {teams?.teams?.map((team, idx) => {
          return (
            <tr key={team.id}>
              <td>{idx + 1}</td>
              <td>{team.name}</td>
              {teams.puzzles.map((puzzle) => {
                const teamPuzzle = team.puzzles.filter((x) => x.puzzleCode === puzzle.code)[0];
                return (
                  <td key={puzzle.code}>
                    {teamPuzzle?.completed && <>✅</>}
                    {!teamPuzzle?.completed && (
                      <FontAwesomeIcon icon="hourglass-half" title={teamPuzzle?.locksCompleted.toString() ?? '0'} />
                    )}
                  </td>
                );
              })}
              <td>{team.wrongAnswers}</td>
              <td>
                {team.timeRemaining && <>{FormatDuration(team.timeRemaining)}</>}
                {!team.timeRemaining && <FontAwesomeIcon icon="hourglass-half" />}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Leaderboard;
