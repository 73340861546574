import React, { useContext, useEffect } from 'react';
import * as QueryString from 'query-string';
import { useHistory } from 'react-router';
import Button from 'reactstrap/lib/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import RevengeContext from '../../services/RevengeContext';

const GameBombDefused = () => {
  const history = useHistory();
  const qs = QueryString.parse(window.location.search);

  const nextUrl = `/game/completed?returnUrl=${qs.returnUrl || ''}`;

  const videoEnded = () => {
    history.push(nextUrl);
  };

  return (
    <div className="bomb-defused">
      <h3 className="orbi">Gefeliciteerd</h3>

      <p>Je hebt de bom onschadelijk gemaakt!</p>

      <video autoPlay={true} controls={true} muted={false} onEnded={videoEnded}>
        <source src="https://cdn.dewraakvanlind.nl/videos/bomb-defused.mp4" type="video/mp4" />
        <track label="Nederlands" kind="subtitles" srcLang="nl" src="/subtitles/bomb-defused.vtt" default></track>
      </video>

      <Button className="btn btn-info mt-3" tag={Link} to={nextUrl}>
        Doorgaan
        <FontAwesomeIcon icon="chevron-right" className="ml-3" />
      </Button>
    </div>
  );
};

export default GameBombDefused;
