import React, { useContext, useEffect, useRef, useState } from 'react';
import useAxios from '../services/UseAxios';
import ReactTimeAgo from 'react-time-ago';
import RevengeContext from '../services/RevengeContext';

const WaitingRoomTeams = () => {
  const [teams, setTeams] = useState();
  const [teamsLoading, setTeamsLoading] = useState(true);
  const { getRequest } = useAxios();
  const mountedRef = useRef(true);
  const { journalEvents } = useContext(RevengeContext);

  const fetchData = async () => {
    await getRequest('/waitingroom')
      .then((result) => {
        setTeams(result.data);
      })
      .finally(() => {
        setTeamsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();

    const sub = journalEvents.subscribe({
      next: (data) => {
        if (data || !mountedRef.current) {
          return null;
        }

        fetchData();
      },
    });

    return () => {
      sub.dispose();
      mountedRef.current = false;
    };
  }, []);

  if (teamsLoading) {
    return null;
  }

  return (
    <div className="card mt-3 mb-3">
      <div className="card-header orbi-header">De volgende teams spelen mee!</div>
      <div className="card-body d-flex justify-content-between align-items-center">
        <table className="table table-striped table-dashboard">
          <thead>
            <tr>
              <th>Teamnaam</th>
              <th>Tijdstip van aanmelding</th>
            </tr>
          </thead>
          <tbody>
            {teams?.teams?.map((team) => {
              return (
                <tr key={team.id}>
                  <td>{team.name}</td>
                  <td>
                    <ReactTimeAgo date={new Date(team.activated)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WaitingRoomTeams;
