import React, { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { isTouchDevice } from '../../services/MobileHelper';
import RevengeContext from '../../services/RevengeContext';
import useAxios from '../../services/UseAxios';

const TeamEdit = ({ setShowEdit }) => {
  const { gameContext, setGameContext } = useContext(RevengeContext);
  const [teamName, setTeamName] = useState(gameContext.team.name);
  const { postRequest } = useAxios();

  const handleChange = (e) => {
    setTeamName(e.target.value);
  };

  const EditTeamRequest = async (e) => {
    e.preventDefault();

    await postRequest('/team/edit', { name: teamName })
      .then((result) => {
        setGameContext(result.data);
        setShowEdit(false);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          return;
        }

        console.log(err);
      });
  };

  return (
    <div className="no-team" key="no-team">
      <form onSubmit={EditTeamRequest}>
        <div className="form-group">
          <Row>
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder="Team name"
                required={true}
                value={teamName}
                autoFocus={!isTouchDevice()}
                onChange={handleChange}
              />
            </Col>
            <Col>
              <Button className="btn btn-primary" type="submit">
                Verander teamnaam
              </Button>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};

export default TeamEdit;
