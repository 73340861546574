import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import useAxios from '../../services/UseAxios';
import { FormatInputDate } from '../../services/DateHelper';
import Button from 'reactstrap/lib/Button';
import GameHeader from '../../components/Admin/GameHeader';
import Moment from 'react-moment';
import 'moment/locale/nl';

const AdminTeamEditView = () => {
  const { gameId, teamId } = useParams();
  const backUrl = `/admin/game/${gameId}`;

  const [team, setTeam] = useState({});
  const [game, setGame] = useState();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  const { getRequest, postRequest, deleteRequest } = useAxios();

  const fetchGame = async () => {
    await getRequest(`/admin/games/${gameId}`)
      .then((result) => {
        setGame(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTeam = async () => {
    await getRequest(`/admin/teams/team/${teamId}`)
      .then((result) => {
        result.data.edit = true;
        setTeam(result.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGame();

    if (teamId === 'new') {
      setTeam({ gameId: gameId });
      setLoading(false);
      return;
    }

    fetchTeam();

    return () => {};
  }, [gameId, teamId]);

  const editTeamProperty = (key, value) => {
    team[key] = value;
    setTeam({ ...team });
  };

  const editTeam = async (e) => {
    e.preventDefault();

    const url = team.edit ? '/admin/teams/edit' : '/admin/teams/create';

    setSubmitting(true);
    await postRequest(url, team)
      .then((result) => {
        if (team.edit) {
          history.push(backUrl);
        } else {
          history.push(`/admin/game/${gameId}/teams/${result.data.id}`);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const deleteTeam = async () => {
    if (!window.confirm('Are you sure you want to delete this team?')) {
      return;
    }

    await deleteRequest(`/admin/teams/${team.id}`)
      .then((result) => {
        history.push(backUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loading || !team || !game) {
    return null;
  }

  return (
    <>
      <GameHeader game={game} />
      <div className="mt-3 admin-team-edit">
        <div className="row">
          <div className="col-8 offset-2">
            <form className="form" onSubmit={editTeam}>
              {team.edit && (
                <div className="row form-group">
                  <label className="col-3">Code</label>
                  <input type="text" className="form-control col-9" readOnly={true} value={team.code} disabled={submitting} />
                </div>
              )}
              <div className="row form-group">
                <label className="col-3">Naam</label>
                <input
                  type="text"
                  className="form-control col-9"
                  required={true}
                  autoComplete="off"
                  value={team.contactName ?? ''}
                  disabled={submitting}
                  onChange={(e) => editTeamProperty('contactName', e.target.value)}
                />
              </div>
              <div className="row form-group">
                <label className="col-3">Email</label>
                <input
                  type="email"
                  className="form-control col-9"
                  required={true}
                  autoComplete="off"
                  value={team.email ?? ''}
                  disabled={submitting}
                  onChange={(e) => editTeamProperty('email', e.target.value)}
                />
              </div>
              <div className="row form-group">
                <label className="col-3">Telefoon</label>
                <input
                  type="text"
                  className="form-control col-9"
                  required={true}
                  autoComplete="off"
                  value={team.phone ?? ''}
                  disabled={submitting}
                  onChange={(e) => editTeamProperty('phone', e.target.value)}
                />
              </div>
              <div className="row form-group">
                <label className="col-3">Aantal Spelers</label>
                <input
                  type="number"
                  className="form-control col-9"
                  required={true}
                  autoComplete="off"
                  value={team.numberOfPlayers ?? ''}
                  disabled={submitting}
                  onChange={(e) => editTeamProperty('numberOfPlayers', e.target.value)}
                />
              </div>
              <div className="row form-group">
                <label className="col-3">Teamnaam</label>
                <input
                  type="text"
                  className="form-control col-9"
                  autoComplete="off"
                  value={team.name ?? ''}
                  disabled={submitting}
                  onChange={(e) => editTeamProperty('name', e.target.value)}
                />
              </div>
              {team.edit && (
                <>
                  <div className="row form-group">
                    <label className="col-3">Bevestiging gestuurd</label>
                    <input
                      type="datetime-local"
                      className="form-control col-9"
                      required={false}
                      autoComplete="off"
                      value={team.emailConfirmationSent ? FormatInputDate(team.emailConfirmationSent) : ''}
                      disabled={submitting}
                      onChange={(e) => editTeamProperty('emailConfirmationSent', e.target.value)}
                    />
                  </div>
                  <div className="row form-group">
                    <label className="col-3">Betaald</label>
                    <input
                      type="datetime-local"
                      className="form-control col-9"
                      required={false}
                      autoComplete="off"
                      value={team.paid ? FormatInputDate(team.paid) : ''}
                      disabled={submitting}
                      onChange={(e) => editTeamProperty('paid', e.target.value)}
                    />
                  </div>
                  <div className="row form-group">
                    <label className="col-3">Borg terug</label>
                    <input
                      type="datetime-local"
                      className="form-control col-9"
                      required={false}
                      autoComplete="off"
                      value={team.depositReturned ? FormatInputDate(team.depositReturned) : ''}
                      disabled={submitting}
                      onChange={(e) => editTeamProperty('depositReturned', e.target.value)}
                    />
                  </div>
                </>
              )}

              <div className="row form-group">
                <label className="col-3">Opmerkingen</label>
                <textarea
                  className="form-control col-9"
                  required={false}
                  autoComplete="off"
                  value={team.notes ?? ''}
                  disabled={submitting}
                  onChange={(e) => editTeamProperty('notes', e.target.value)}
                />
              </div>
              <Button className="mt-3 btn btn-info" disabled={submitting}>
                {team.edit ? 'Save team details' : 'Create team'}
              </Button>
            </form>

            {team.edit && !team.emailConfirmationSent && (
              <div className="mt-3 example-email" style={{ backgroundColor: 'white', color: 'black', padding: '25px' }}>
                <p>Hallo {team.contactName},</p>
                <p>Leuk dat jullie deel gaan nemen aan de escape adventure ‘De Wraak van Lind’!</p>
                <p>
                  Met veel plezier hebben wij de afgelopen tijd gewerkt aan deze beleving om zo alle liefhebbers van de Brabantsedag in deze
                  aanhoudende vreemde tijd toch te kunnen voorzien van vermaak in stijl.
                </p>
                <p>We hopen dat het jullie gaat lukken om de Brabantsedag te redden!</p>
                <p>
                  <a href="https://dewraakvanlind.nl/bekijk-de-trailer/" style={{ color: 'black', fontWeight: 700 }}>
                    &lt;Kijk hier de trailer&gt;
                  </a>
                </p>
                <p>
                  Via deze e-mail geven we je graag wat praktische informatie vooraf.
                  <br />
                  Verdere instructies en inhoud van het spel volgt zodra jullie gaan spelen.
                </p>
                <table className="mt-3">
                  <tbody>
                    <tr>
                      <td className="label">Speeldag</td>
                      <td>
                        <Moment locale="nl" date={new Date(game.starts)} format="LLLL" />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Ophalen box</td>
                      <td>
                        <Moment locale="nl" date={new Date(game.starts)} format="LLLL" subtract={{ hours: 1 }} />
                        <br />
                        Kapelstraat 7 (achterom)
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Begin registratie</td>
                      <td>
                        <Moment locale="nl" date={new Date(game.starts)} format="HH:mm" subtract={{ minutes: 15 }} />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Begin spel</td>
                      <td>
                        <Moment locale="nl" date={new Date(game.starts)} format="HH:mm" />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Duur spel</td>
                      <td>maximaal 3 uur</td>
                    </tr>
                    <tr>
                      <td className="label">Locatie</td>
                      <td>
                        Je eigen woonkamer/tuin/garage/feestzaal
                        <br />
                        (per groep één locatie)
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Benodigdheden</td>
                      <td>
                        Laptop met geluid, werkende internet verbinding, ‘de Wraak van Lind’-box, pen &amp; papier, schaar en een fiets kan
                        van pas komen...
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Deze escape adventure kan gespeeld worden door 7-10 personen. Wij adviseren de spelers leeftijd vanaf 10 jaar aan te
                  houden, maar ivm de moeilijkheid van het spel zal een groep van tieners vergezeld moeten worden door 1 á 2 volwassenen.
                  Als jullie hier advies in willen, neem dan gerust contact met ons op. Omdat het spel vanuit één locatie gespeeld zal
                  worden attenderen we jullie er op om de huidige covid-19 RIVM richtlijnen aan te blijven houden om besmettingsrisico’s te
                  voorkomen.
                </p>
                <p>
                  Per groep zijn de kosten van deelnemen €40,-, gelieve deze voor{' '}
                  <Moment locale="nl" date={new Date(game.starts)} format="dddd DD MMMM" /> over te maken op NL04 RABO 0370 9691 11 tnv S.
                  Driessen onder vermelding van je reserveringsnummer en naam.
                </p>
                <p>
                  Het spel gaan jullie spelen op één laptop. Zorg dus voor een goed werkende laptop, met het geluid aan en voorzien van
                  stroom, waar iedereen zicht op heeft.
                </p>
                <p>
                  Jullie hebben ‘de Wraak van Lind’-box nodig om het spel te spelen. Deze kun je ophalen op{' '}
                  <Moment locale="nl" date={new Date(game.starts)} format="DD MMMM" /> tussen 17:30-18:00 uur op de Kapelstraat 7 (achterom
                  bij de poort). Geef hierbij je reserveringsnummer door. Voor deze box vragen wij €10 borg. Graag deze contant meenemen.
                  Deze borg krijg je terug als wij na het spelen van het spel de box inclusief inhoud in goede orde terug ontvangen. Alle
                  papieren in deze box mogen wel gebruikt/gehouden worden.
                </p>
                <p>Let op!: Deze box mag pas tijdens het spel geopend worden.</p>
                <p>
                  Het spel start om stipt 19:00 uur. Als je later begint zal de tijd helaas al gestart zijn. We adviseren om uiterlijk om
                  18:30 uur te verzamelen met de groep op de afgesproken locatie. Om 18:45 uur maken jullie de brief open om exact om 19:00
                  uur te beginnen met het spel. Hoe dit werkt zal dan duidelijk worden.
                </p>
                <p>
                  Tijdens het spel hebben jullie een contactpersoon die jullie via de chatfunctie in het systeem kunnen bereiken. Werk met
                  hem samen! Als je vastloopt mag je hem ook om hints vragen.
                </p>
                <p>
                  Mocht er echt iets helemaal fout lopen, dan kun je ons bereiken op noodnummer: 06-44996878. Echt alleen gebruiken als
                  jullie technisch vastlopen, dus niet gebruiken voor hints in het spel.
                </p>
                <p>
                  Nog een laatste tip: Niet alleen voor de voorpret, maar zeker ook voor tijdens het spel kan het handig zijn om een
                  groepsapp samen aan te maken.
                </p>
                <p>
                  Volg ons op Instagram en/of Facebook @escape.adventure.dwvl. We vinden het altijd leuk om getaged te worden in een foto
                  over het spel of met jullie groep, maar pas svp wel op dat er geen puzzelgegevens zichtbaar zijn zodat het ook nog
                  spannend blijft voor groepen die na jullie komen.
                </p>
                <p>Dank je wel en</p>
                <p>Heel veel speel-plezier!</p>
                <p>Groeten,</p>
                <p>Team Escape Adventure Heeze</p>
              </div>
            )}
          </div>
        </div>

        {team.edit && (
          <Button className="btn btn-danger float-right" style={{ fontSize: '10px' }} onClick={deleteTeam}>
            Team verwijderen
          </Button>
        )}
      </div>

      <Button type="submit" className="mt-5 btn btn-info" tag={Link} to={backUrl}>
        <FontAwesomeIcon icon="chevron-left" className="mr-3" />
        Ga terug
      </Button>
    </>
  );
};

export default AdminTeamEditView;
