import axios from 'axios';
import { useEffect, useState } from 'react';

axios.defaults.baseURL = '/api';
axios.defaults.withCredentials = false;
axios.defaults.timeout = 30000;

const useAxios = () => {
  const [isAxiosReady, setIsAxiosReady] = useState(false);
  const [gameId, setGameId] = useState();

  useEffect(() => {
    const teamId = localStorage.getItem('teamId');
    const teamCode = localStorage.getItem('teamCode');

    if (teamId && teamCode) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${teamId}|${teamCode}`;
    }

    setIsAxiosReady(true);
  }, []);

  useEffect(() => {
    if (gameId) {
      axios.defaults.headers.common['GameId'] = gameId;
    }

    return () => {};
  }, [gameId]);

  const getRequest = async (url, config) => {
    return await axios.get(url, config);
  };

  const postRequest = async (url, data, config) => {
    return await axios.post(url, data, config);
  };

  const deleteRequest = async (url, config) => {
    return await axios.delete(url, config);
  };

  return { getRequest, postRequest, deleteRequest, isAxiosReady, gameId, setGameId };
};

export default useAxios;
