import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import RevengeContext from '../../services/RevengeContext';

const PuzzleButton = ({ puzzle, sounds, puzzleCode }) => {
  let indicatorClass;

  if (!puzzle.locks || !puzzle.locks[0]?.active) {
    indicatorClass = 'indicator-inactive';
  } else if (puzzle.completed) {
    indicatorClass = 'indicator-complete';
  } else {
    indicatorClass = 'indicator-incomplete';
  }

  return (
    <Link
      to={`/game/puzzle/${puzzle.code}`}
      className={`game-button ${indicatorClass} ${puzzle.code} ${puzzle.code === puzzleCode ? 'active' : ''} ${
        indicatorClass === 'indicator-inactive' ? 'disabled' : ''
      }`}
      key={puzzle.code}
      title={`Puzzle ${puzzle.code}`}
      onClick={(e) => {
        if (indicatorClass === 'indicator-inactive') {
          e.preventDefault();
          sounds.lockNotAvailable.play();

          return;
        }

        sounds.buttonPress.play();
      }}
    >
      {puzzle.code === 'BOMB' && <FontAwesomeIcon icon="bomb" size="2x" className="bomb" />}
      {puzzle.code !== 'BOMB' && <img src={`/images/puzzles/${puzzle.code}.png`} />}
    </Link>
  );
};

const PuzzleButtons = () => {
  const { gameContext, sounds, puzzleCode } = useContext(RevengeContext);

  const puzzles = gameContext.game.puzzles;

  return (
    <div className="puzzle-buttons">
      {puzzles.map((puzzle) => (
        <PuzzleButton puzzle={puzzle} key={puzzle.code} sounds={sounds} puzzleCode={puzzleCode} />
      ))}
    </div>
  );
};

export default PuzzleButtons;
