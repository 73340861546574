import { useEffect, useState } from 'react';
import { getDateOffset } from './DateHelper';
import useAxios from './UseAxios';

const useGameContext = (setDateOffset) => {
  const { getRequest, isAxiosReady } = useAxios();
  const [gameContextLoading, setGameContextLoading] = useState(true);
  const [gameContextError, setGameContextError] = useState(false);
  const [gameContext, setGameContext] = useState(null);

  const fetchGameContext = async (callback) => {
    await getRequest('/game')
      .then((result) => {
        setGameContext(result.data);
        setDateOffset(getDateOffset(result.data.date));
        setGameContextError(null);
        setGameContextLoading(false);

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        setGameContextError(err);
        setGameContextLoading(false);
      });
  };

  useEffect(() => {
    if (!isAxiosReady) {
      return;
    }

    fetchGameContext();
  }, [isAxiosReady]);

  return { gameContext, setGameContext, gameContextLoading, gameContextError, fetchGameContext };
};

export default useGameContext;
