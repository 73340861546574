import React from 'react';
import Leaderboard from '../../components/Leaderboard';

const LeaderboardView = () => {
  return (
    <div className="leaderboard">
      <h3 className="orbi">Leaderboard</h3>
      <Leaderboard />
    </div>
  );
};

export default LeaderboardView;
