import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import RevengeContext from '../../services/RevengeContext';
import './DateCountdown.css';

const CountdownWaitingRoom = () => {
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const history = useHistory();

  const { gameContext, fetchGameContext, dateOffsetRef } = useContext(RevengeContext);

  useEffect(() => {
    const targetDate = new Date(gameContext.game.starts);

    const tick = (tickId) => {
      const currentDate = new Date() - (dateOffsetRef.current || 0);
      let diff = targetDate - currentDate;

      if (diff < 0) {
        diff = 0;
        clearInterval(tickId);

        setTimeout(() => {
          fetchGameContext(() => {
            history.push('/game/introduction');
          });
        }, 2000);
      }

      setHour(Math.floor((diff / 3600000) % 24));
      setMin(Math.floor((diff / 60000) % 60));
      setSec(Math.floor((diff / 1000) % 60));
    };

    let tickId = setInterval(() => tick(tickId), 1000);
    tick(tickId);

    return () => {
      clearInterval(tickId);
    };
  }, [gameContext?.game?.starts]);

  let hours = hour.toString().padStart(2, '0');
  let mins = min.toString().padStart(2, '0');
  let secs = sec.toString().padStart(2, '0');

  return (
    <div className="card mt-3 mb-3">
      <div className="card-header orbi-header">Nog even wachten. Het spel begint over:</div>
      <div className="card-body d-flex justify-content-between align-items-center">
        <span className="odometer-block start-ticker">
          <span className="hour">
            <span>{hours[0]}</span>
            <span>{hours[1]}</span>
          </span>
          <span className="sep">:</span>
          <span className="min">
            <span>{mins[0]}</span>
            <span>{mins[1]}</span>
          </span>
          <span className="sep">:</span>
          <span className="sec">
            <span>{secs[0]}</span>
            <span>{secs[1]}</span>
          </span>
        </span>
      </div>
    </div>
  );
};

export default CountdownWaitingRoom;
