const supported = () => {
  return window.Notification;
};

const RequestNotificationPermissions = () => {
  if (!supported()) {
    return;
  }

  if (Notification.permission === 'default') {
    Notification.requestPermission();
  }
};

const SendNotification = (title, options, onclick) => {
  if (!supported()) {
    return;
  }

  console.log('Got a notification!', title);

  if (Notification.permission === 'denied') {
    console.log('Notification permissions denied, aborting.');
    return;
  }

  title = title || 'De Wraak van Lind';
  options = options || {};
  options.icon = options.icon || '/images/jop.png';

  const doIt = () => {
    const notification = new Notification(title, options);
    if (onclick) {
      notification.onclick = () => onclick();
    }
  };

  if (Notification.permission === 'granted') {
    doIt();
  } else {
    Notification.requestPermission().then(() => doIt());
  }
};

export { RequestNotificationPermissions, SendNotification };
