import React, { useContext } from 'react';
import RevengeContext from '../services/RevengeContext';

const CreditsView = () => {
  const { gameContext } = useContext(RevengeContext);

  return (
    <div className="orbi mt-3">
      <h3 className="orbi mt-5">Credits</h3>
      <p>Dit spel is mede mogelijk gemaakt door:</p>

      <h4 className="orbi mt-3">Kernteam</h4>
      <ul>
        <li>Stijn Driessen</li>
        <li>Wijnand Driessen</li>
        <li>Martine Engels</li>
        <li>Steve McGill</li>
        <li>Maarten van der Velden</li>
      </ul>
      <h4 className="orbi mt-3">Film crew</h4>
      <ul>
        <li>Joey van Bree</li>
        <li>Jelle de Cameraman</li>
        <li>Nathan van Groenigen</li>
        <li>Sander Kuipers</li>
        <li>Monne Tuinhout</li>
      </ul>
      <h4 className="orbi mt-3">Acteurs</h4>
      <ul>
        {gameContext?.game?.ended && <li>Frans Bakermans</li>}
        <li>Dirk van Gaal</li>
        <li>Michiel van der Heijden</li>
        <li>Nathalie Nuijts</li>
        <li>Henk Kemperman</li>
        <li>Amancio Smulders</li>
        <li>Olav Veldhuizen</li>
      </ul>
      <h4 className="orbi mt-3">Met dank aan</h4>
      <ul>
        <li>Familie van Gennip</li>
      </ul>
    </div>
  );
};

export default CreditsView;
