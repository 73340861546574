import React, { useContext, useEffect, useRef, useState } from 'react';
import useAxios from '../services/UseAxios';
import ErrorCard from './Cards/ErrorCard';
import { FormatDate } from '../services/DateHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import * as QueryString from 'query-string';
import { isTouchDevice } from '../services/MobileHelper';
import RevengeContext from '../services/RevengeContext';
import { SendNotification } from '../services/NotificationHelper';

const ChatLockName = ({ puzzleCode, lockSequence }) => {
  return (
    <>
      {puzzleCode === 'BOMB' && <FontAwesomeIcon icon="bomb" size="1x" className="bomb" />}
      {puzzleCode !== 'BOMB' && (
        <>
          Slot <img src={`/images/puzzles/${puzzleCode}w.svg`} width="3%" height="3%" />
          {lockSequence}
        </>
      )}
    </>
  );
};

const Chat = ({ title, introChatsSeen = [], setIntroChatsSeen, chatsUnseen = [], setChatsUnseen, chatsSeen = {}, setChatsSeen }) => {
  const mountedRef = useRef(true);
  const [chatLoading, setChatLoading] = useState(true);
  const [chatError, setChatError] = useState(null);
  const [chats, setChats] = useState([]);
  const chatsRef = useRef();
  chatsRef.current = chats;
  const chatsScrollRef = useRef();

  const [messageSending, setMessageSending] = useState(false);
  const textInput = useRef(null);
  const { postRequest } = useAxios();
  const history = useHistory();
  const { gameContext, gameContextRef, teamId, teamIdRef, setTeamId, sounds, chatEvents, puzzleLock, setPuzzleLock } =
    useContext(RevengeContext);
  let puzzleCode = null;
  let lockSequence = null;

  if (puzzleLock) {
    puzzleCode = puzzleLock.puzzleCode;
    lockSequence = puzzleLock.lockSequence;
  }

  let chatTimeout = null;
  let chatTimeout2 = null;

  const chatSeen = async (teamId, chatId, puzzleCode, lockSequence) => {
    chatsSeen = chatsSeen || {};
    const key = puzzleCode ? `${teamId}-${puzzleCode}-${lockSequence}` : `${teamId}-general`;
    chatsSeen[key] = chatId;
    setChatsSeen({ ...chatsSeen });
    localStorage.setItem('chatsSeen', JSON.stringify(chatsSeen));
  };

  useEffect(() => {
    if (gameContext?.team?.admin && chats && chats.length > 0) {
      const lastChat = chats.filter((x) => !x.fromAdmin).slice(-1)[0];
      if (lastChat) {
        chatSeen(teamId, lastChat.id, lastChat.puzzleCode, lastChat.lockSequence);
      }
    }

    chatsScrollRef?.current && chatsScrollRef.current.scrollTo(0, chatsScrollRef.current.scrollHeight);
  }, [chats]);

  useEffect(() => {
    window.isFs = false;

    setTimeout(() => chatsScrollRef?.current && chatsScrollRef.current.scrollTo(0, chatsScrollRef.current.scrollHeight), 500);

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    let url = `/chat`;
    if (puzzleCode) {
      url = `/chat/${puzzleCode}/${lockSequence}`;
    }

    if (gameContext.team?.admin) {
      if (!teamId) {
        return;
      }

      url = `/chat/${teamId}`;
      if (puzzleCode) {
        url = `/chat/${teamId}/${puzzleCode}/${lockSequence}`;
      }
    }

    const insertIntroChat = (data) => {
      let introChat = null;

      if (!puzzleCode) {
        introChat = '/msg:introduction';
      } else if (puzzleCode === 'A1') {
        if (lockSequence === 2) {
          introChat = 'Ik heb deze cryptische tekst gevonden. Kun jij hier iets mee?';
        }
      } else if (puzzleCode === 'A2') {
        if (lockSequence === 2) {
          introChat = 'Weet jij wat ik hier mee aan moet?';
        }
      } else if (puzzleCode === 'A3') {
        if (lockSequence === 2) {
          introChat = 'Ik heb deze cryptische tekst gevonden. Kun jij hier iets mee?';
        }
      } else if (puzzleCode === 'A4') {
        if (lockSequence === 1) {
          introChat = 'De tijd dringt...';
        } else if (lockSequence === 2) {
          introChat = 'Ik heb geen flauw idee wat ze hiermee bedoelen. Jij wel?';
        } else if (lockSequence === 3) {
          introChat =
            'Zijn de vraagtekens startnummers? Misschien kan Brabantsfaam.nl je helpen als je een laptop hebt: \n\nJaar > Vriendenkring > Informatie (linksonder).\n\n' +
            'Trouwens, er komen drie cijfers uit dit raadsel, maar we hebben twee woorden nodig. Kunnen we de cijfers ergens anders kwijt?';
        }
      } else if (puzzleCode === 'A5') {
        if (lockSequence === 2) {
          introChat = 'Kijk of Rick een oplossing heeft achtergelaten in het dorp! Let op, ik denk dat je wat mee moet nemen...';
        }
      } else if (puzzleCode === 'BOMB') {
        introChat =
          'De terminal is open! Nu kunnen we de bom ontmantelen! \n\n' +
          'Maar heb je de legpuzzel al compleet?\n\n' +
          'Zo niet, ga naar het geboortehuis van de Brabantsedag. Daar vind je een verborgen aanwijzing om het kleine houten kistje te kunnen openen en het decodeerschema af te maken.\n\n' +
          'Zodra je de puzzel compleet hebt, gebruik het schema op de legpuzzel om de laatste code van 5 karakters te vinden. Laten we de Brabantsedag redden!';
      }

      if (introChat) {
        if (!puzzleCode) {
          data.unshift({
            fromAdmin: true,
            id: 0,
            date: gameContext.game.starts,
            message: introChat,
            seen: true,
            teamId: teamId,
          });
        } else {
          const puzzle = gameContext.game.puzzles?.filter((x) => x.code === puzzleCode)[0];
          const lock = puzzle?.locks.filter((x) => x.sequence === lockSequence)[0];

          if (lock) {
            introChat = {
              fromAdmin: true,
              id: 0,
              date: lock.active,
              message: introChat,
              seen: true,
              teamId: teamId,
            };

            data.unshift(introChat);
          }
        }
      }

      return data;
    };

    const fetchData = async () => {
      await postRequest(url)
        .then((result) => {
          if (!mountedRef.current) {
            return;
          }

          const newChats = insertIntroChat(result.data);
          const seenKey = puzzleCode ? `${puzzleCode}.${lockSequence}` : 'general';
          if (introChatsSeen.includes(seenKey)) {
            setChats(newChats);
          } else {
            introChatsSeen.push(seenKey);
            setIntroChatsSeen && setIntroChatsSeen([...introChatsSeen]);

            if (newChats.length === 1 && newChats[0].id === 0) {
              setChats([]);
              chatTimeout = setTimeout(() => {
                setChats(newChats);
                sounds.chatJop.play();
              }, 2000);
            } else {
              setChats(newChats);
            }
          }

          if (chatsUnseen.includes(seenKey)) {
            chatsUnseen = chatsUnseen.filter((x) => x !== seenKey);
            setChatsUnseen([...chatsUnseen]);
          }

          setChatLoading(false);
          setChatError(null);
        })
        .catch((err) => {
          console.log(err);
          if (!mountedRef.current) {
            return;
          }

          setChatError(err);
          setChatLoading(false);
        });
    };

    fetchData();

    const sub = chatEvents.subscribe({
      next: (data) => {
        if (!mountedRef.current) {
          return;
        }

        const isAdmin = gameContextRef.current?.team?.admin === true;
        const isCorrectTeam = !isAdmin || data.teamId.toUpperCase() === teamIdRef.current.toUpperCase();

        let isCorrectLock = false;
        if (puzzleCode) {
          isCorrectLock = puzzleCode && puzzleCode === data.puzzleCode && lockSequence === data.lockSequence;
        } else {
          isCorrectLock = data.puzzleCode === null && data.lockSequence === null;
        }

        if (data.fromAdmin && !isAdmin && !data.message.startsWith('/msg:')) {
          sounds.chatText.play();
        }

        if (!isAdmin) {
          if (data.message === '/msg:game-completed') {
            const url = gameContextRef.current.game.ended ? '/game/completed?after=1' : '/game/bomb-defused';
            setTimeout(() => history.push(url), 5000);
          }
        }

        if (isCorrectTeam && isCorrectLock) {
          // Already showing the correct chat, just update it

          const updateChats = () => {
            const newChats = chatsRef.current ? [...chatsRef.current] : [];
            newChats.push(data);
            setChats(newChats);
          };

          if (data.message.startsWith('/msg:')) {
            chatTimeout2 = setTimeout(updateChats, 3000);
          } else {
            updateChats();
          }

          return;
        }

        if (!isAdmin) {
          if (data.fromAdmin && !data.message.startsWith('/msg:')) {
            SendNotification('Je hebt een bericht van Jop', { body: data.message }, () => {
              const newUrl = data.puzzleCode ? `/game/puzzle/${data.puzzleCode}/lock/${data.lockSequence}` : '/game';
              history.push(newUrl);
            });

            const seenKey = data.puzzleCode ? `${data.puzzleCode}.${data.lockSequence}` : 'general';
            if (!chatsUnseen.includes(seenKey)) {
              chatsUnseen.push(seenKey);
              setChatsUnseen([...chatsUnseen]);
            }
          }
        }
      },
    });

    return () => {
      chatTimeout && clearTimeout(chatTimeout);
      chatTimeout2 && clearTimeout(chatTimeout2);

      sub.dispose();
    };
  }, [teamId, puzzleLock]);

  if (chatLoading || !teamId) {
    return null;
  }

  if (chatError) {
    return <ErrorCard title="Fout" message="Chat kan niet worden geladen" />;
  }

  const SendMessage = async (e) => {
    e.preventDefault();

    const url = gameContext.team?.admin ? `/chat/send/${teamId}` : '/chat/send';

    setMessageSending(true);
    const newMessage = document.getElementById('chat-form').elements['chat-message'].value;
    await postRequest(url, {
      message: newMessage,
      puzzleCode: puzzleCode,
      lockSequence: lockSequence,
    })
      .then((result) => {
        document.getElementById('chat-form').elements['chat-message'].value = '';
      })
      .catch((err) => {
        //setError(err.response?.data?.message ?? err.response?.data?.detail ?? err.response?.statusText);
      })
      .finally(() => {
        setMessageSending(false);
        !isTouchDevice() && textInput.current.focus();
      });
  };

  const goFull = (e) => {
    if (!window.isFs) {
      window.isFs = true;
      var p = e.target;
      var fn_enter =
        p.requestFullscreen || p.webkitRequestFullscreen || p.mozRequestFullScreen || p.oRequestFullscreen || p.msRequestFullscreen;
      fn_enter.call(p);
    }
  };

  const videoClick = (e) => {
    if (window.isFs || !e.target.paused) {
      return;
    }

    e.target.play();
    goFull(e);
  };

  const exitFull = (e) => {
    window.isFs = false;
    var p = e.target;
    var fn_exit = p.exitFullScreen || p.webkitExitFullScreen || p.mozExitFullScreen || p.oExitFullScreen || p.msExitFullScreen;
    fn_exit.call(p);

    const qs = QueryString.parse(window.location.search);

    if (qs.returnUrl) {
      history.push(qs.returnUrl);
    }
  };

  const ChatEntry = ({ chat, Child }) => {
    const adminClass = chat.fromAdmin
      ? gameContext.team?.admin
        ? 'from-me'
        : 'from-them'
      : gameContext.team?.admin
      ? 'from-them'
      : 'from-me';

    return (
      <div className={`chat-message mb-3 ${adminClass}`} key={chat.id} title={FormatDate({ date: chat.date })}>
        <div className="chat-message__message-outer">
          <Child />
        </div>
      </div>
    );
  };

  const MessageOrVideo = ({ chat }) => {
    if (!chat.fromAdmin || !chat.message.startsWith('/msg:')) {
      const child = () => <>{chat.message}</>;
      return <ChatEntry chat={chat} Child={child} />;
    }

    let message = chat.message.substring(5);
    if (message.startsWith('wrong:')) {
      const wrongPhrases = [
        'Helaas, #ANSWER# is niet goed. Laten we verder zoeken.',
        'Helaas, #ANSWER# is niet goed. Laten we verder zoeken.',
        'Helaas, #ANSWER# is niet goed. Laten we verder zoeken.',
        'Helaas, #ANSWER# is niet goed. Laten we verder zoeken.',
        'Jammer, #ANSWER# is niet goed. Probeer nog eens...',
        'Jammer, #ANSWER# is niet goed. Probeer nog eens...',
        'Jammer, #ANSWER# is niet goed. Probeer nog eens...',
        'Jammer, #ANSWER# is niet goed. Probeer nog eens...',
        '#ANSWER# is niet goed. Elke minuut telt. Steek de koppen bij elkaar en probeer het opnieuw.',
        '#ANSWER# is helaas fout. Maar met vereende krachten komen we er zeker uit!',
      ];

      const wrongAnswer = `'${message.substring(8)}'`;
      const wrongPhraseIndex = parseInt(message.substring(6, 7)) % wrongPhrases.length;

      const wrongMessage = wrongPhrases[wrongPhraseIndex].replace('#ANSWER#', wrongAnswer);

      const child = () => <span className="wrong-answer">{wrongMessage}</span>;
      return <ChatEntry chat={chat} Child={child} />;
    }

    const videoCode = message;
    const videos = ['trailer', 'game-midway', 'bomb-defused', 'introduction', 'game-2symbols'];
    const showVideo = videos.includes(videoCode);

    switch (message) {
      case 'introduction':
        message =
          'Super dat jullie me gaan helpen! Ik zal je hier berichten sturen als ik dingen ontdek. Bekijk eventueel hier de missie video terug.';
        break;
      case 'puzzle-solved':
        message = 'Yes! Goed gedaan! Jullie hebben het symbool ontgrendeld!';
        break;
      case 'lock-solved':
        const correctPhrases = {
          'A1.1': 'Jullie zijn goed bezig! Door naar een nieuwe blokkade om op te lossen!',
          'A1.2': 'Geen puzzel te moeilijk voor dit team! Zet hem op, de Brabantsedag mag niet ten onder gaan!',
          'A2.1': 'En nu doorpakken! Als deze gekraakt is, lukt de volgende zeker!',
          'A2.2': 'Opnieuw een code ontcijferd! Zorg dat je dit tempo vasthoudt!',
          'A3.1': 'En nu naar de volgende uitdaging! Samen gaat dit ons lukken!',
          'A3.2': 'Opnieuw een code ontcijferd! Zorg dat je dit tempo vasthoudt!',
          'A4.1': 'Geen puzzel te moeilijk voor dit team! Zet hem op, de Brabantsedag mag niet ten onder gaan!',
          'A4.2': 'Opnieuw een code ontcijferd! Zorg dat je dit tempo vasthoudt!',
          'A5.1': 'En nu doorpakken! Als deze gekraakt is, lukt de volgende zeker!',
        };

        const lockKey = `${chat.puzzleCode}.${chat.lockSequence}`;
        message = correctPhrases[lockKey] ?? 'Goedzo! Laten we snel het volgende slot kraken!';
        break;
      case 'game-completed':
        message = 'Wooohoo!!! Het is ons gelukt!!!';
        break;
      case 'game-2symbols':
        message = 'Goed zo, alweer een slot gekraakt!';
        break;
      case 'game-midway':
        message = 'Al over de helft!';
        break;
      case 'bomb-revealed':
        message = 'Jullie zijn er bijna!';
        break;
      default:
        //message = null;
        break;
    }

    const messageEntry = () => <>{message}</>;
    const videoEntry = () => (
      <video
        autoPlay={false}
        controls={true}
        muted={false}
        onPlay={(e) => goFull(e)}
        onEnded={(e) => exitFull(e)}
        onClick={(e) => videoClick(e)}
        poster="/images/video-poster-jop1.jpg"
        preload="none"
      >
        <source src={`https://cdn.dewraakvanlind.nl/videos/${videoCode}.mp4`} type="video/mp4" />
        <track label="Nederlands" kind="subtitles" srcLang="nl" src={`/subtitles/${videoCode}.vtt`} default></track>
      </video>
    );

    return (
      <>
        {message && <ChatEntry chat={chat} Child={messageEntry} />}
        {showVideo && <ChatEntry chat={chat} Child={videoEntry} />}
      </>
    );
  };

  const hasChatWaiting = chatsUnseen.length > 0;
  let chatUrl = null;
  let lockName = null;
  if (hasChatWaiting) {
    const firstChat = chatsUnseen[0];
    if (firstChat) {
      const parts = firstChat.split('.');
      if (firstChat === 'general') {
        chatUrl = '/game';
        lockName = <></>;
      } else {
        chatUrl = `/game/puzzle/${parts[0]}/lock/${parts[1]}`;
        lockName = (
          <>
            bij <ChatLockName puzzleCode={parts[0]} lockSequence={parts[1]} />
          </>
        );
      }
    }
  }

  return (
    <>
      {hasChatWaiting && (
        <div
          className="chat-waiting"
          onClick={() => {
            history.push(chatUrl);
          }}
        >
          Nieuw bericht ontvangen {lockName}
          <br />
          Tik om te openen.
        </div>
      )}
      <div className="chat-header">
        <div className="clue-heading badge mb-1">
          {puzzleCode && <ChatLockName puzzleCode={puzzleCode} lockSequence={lockSequence} />}
          {!puzzleCode && `Chat met Jop`}
          {title || ''}
        </div>
      </div>
      <div className={`chats ${hasChatWaiting ? 'reduced-height' : ''}`} ref={chatsScrollRef}>
        {chats.length > 0 && (
          <div className={`chat-body`}>
            {chats.map((chat) => {
              if (!chat.message) {
                return;
              }

              return <MessageOrVideo chat={chat} key={chat.id} />;
            })}
          </div>
        )}
      </div>
      <div className="dock-bottom">
        <form className="form" id="chat-form" onSubmit={(e) => SendMessage(e)}>
          <input
            type="text"
            className="form-control"
            id="chat-message"
            name="chat-message"
            ref={textInput}
            placeholder="Plaats hier je bericht"
            required={true}
            autoComplete="off"
            disabled={messageSending}
          />
          <button type="submit" className="btn btn-primary submit-icon" disabled={messageSending}>
            <FontAwesomeIcon icon="paper-plane" />
          </button>
        </form>
      </div>
      <div
        className="chat-home"
        onClick={() => {
          if (gameContext.team?.admin) {
            setTeamId(null);
            setPuzzleLock(null);
          } else {
            history.push('/game');
          }
        }}
      ></div>
    </>
  );
};

export default Chat;
