import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TeamEdit from '../../components/Team/TeamEdit';
import RevengeContext from '../../services/RevengeContext';
var QRCode = require('qrcode.react');

export const TeamDashboardView = () => {
  const { gameContext } = useContext(RevengeContext);
  const [showEdit, setShowEdit] = useState(false);

  const LeaveTeam = () => {
    if (!window.confirm('Weet je zeker dat je het team wil verlaten?')) {
      return;
    }

    localStorage.removeItem('teamId');
    localStorage.removeItem('teamCode');
    window.location.href = '/';
  };

  const Inner = () => {
    const joinLink = `${window.location.origin}/team/join/${gameContext.team.code}`;

    return (
      <div>
        {!showEdit && (
          <h1>
            {gameContext.team.name}{' '}
            <FontAwesomeIcon icon="edit" className="ml-3" title="Verander teamnaam" onClick={() => setShowEdit(true)} />
          </h1>
        )}
        {showEdit && <TeamEdit setShowEdit={setShowEdit} />}

        <div className="card mb-3">
          <div className="card-body">
            <h5 className="card-text">Optie 1: QR Code</h5>
            <p>
              {' '}
              Scan de QR code met je telefoon of ga naar deze link: <a href={joinLink}>{joinLink}</a>
            </p>
            <QRCode value={joinLink} size={125} />
          </div>
        </div>

        <div className="game-status-started">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-text">Optie 2: teamcode</h5>
              <p className="card-text">
                Ga op je laptop naar <a href="https://app.dewraakvanlind.nl">app.dewraakvanlind.nl</a> en sluit je aan bij dit team met
                teamcode: <b>{gameContext.team.code}</b>
              </p>
            </div>
          </div>
        </div>

        <Button to={`/game`} tag={Link} className="btn btn-info">
          Naar het spel!
        </Button>

        <Button className="btn btn-danger float-right" style={{ marginTop: '100px', fontSize: '10px' }} onClick={() => LeaveTeam()}>
          Team verlaten
        </Button>
      </div>
    );
  };

  return (
    <div className="team-dashboard">
      <div className="mb-5">
        <Inner key="inner" />
      </div>
    </div>
  );
};

export default TeamDashboardView;
