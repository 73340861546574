import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import RevengeContext from '../../services/RevengeContext';

const GameLockList = ({ puzzle }) => {
  const { sounds, puzzleLock } = useContext(RevengeContext);

  const lockSequence = puzzleLock?.lockSequence;

  return (
    <div className="game-lock-list">
      {puzzle.locks.map((lock) => {
        const lockLink = `/game/puzzle/${puzzle.code}/lock/${lock.sequence}`;
        let indicatorClass;

        if (!lock.active) {
          indicatorClass = 'indicator-inactive';
        } else if (lock.completed) {
          indicatorClass = 'indicator-complete';
        } else if (lock.active) {
          indicatorClass = 'indicator-incomplete';
        }

        return (
          <div
            key={lock.sequence}
            onClick={() => {
              lock.active ? sounds.buttonPress.play() : sounds.lockNotAvailable.play();
            }}
            className={`game-button lock lock-${lock.sequence} ${indicatorClass} ${lock.sequence === lockSequence ? 'active' : ''} ${
              indicatorClass === 'indicator-inactive' ? 'disabled' : ''
            }`}
          >
            {lock.active && (
              <Link to={lockLink} className="lock-sequence">
                <img src={`/images/puzzles/${puzzle.code}.png`} /> <span className="sequence">{lock.sequence}</span>
              </Link>
            )}
            {!lock.active && (
              <div className={`disabled lock-sequence`} disabled>
                <img src={`/images/puzzles/${puzzle.code}.png`} /> <span className="sequence">{lock.sequence}</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default GameLockList;
