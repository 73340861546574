import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { isTouchDevice } from '../../services/MobileHelper';
import RevengeContext from '../../services/RevengeContext';
import useAxios from '../../services/UseAxios';
import ErrorCard from '../Cards/ErrorCard';

const TeamJoin = ({ urlCode }) => {
  const { gameContext, setGameContext } = useContext(RevengeContext);

  const { postRequest } = useAxios();
  const [code, setCode] = useState(urlCode ?? '');
  const [teamName, setTeamName] = useState(gameContext.team?.name ?? '');
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (urlCode) {
      JoinTeamRequest();
    }
  }, []);

  const JoinTeamRequest = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setError(null);
    setSaving(true);
    await postRequest('/team/join', { code: code })
      .then((result) => {
        setGameContext(result.data);
        localStorage.setItem('teamId', result.data.team.id);
        localStorage.setItem('teamCode', result.data.team.code);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response);
        setError(err.response?.data?.message ?? err.response?.data?.detail ?? err.response?.statusText);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const ActivateTeamRequest = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setError(null);
    setSaving(true);
    await postRequest('/team/edit', { name: teamName, activate: true })
      .then((result) => {
        setGameContext(result.data);
      })
      .catch((err) => {
        console.log(err.response);
        setError(err.response?.data?.message ?? err.response?.data?.detail ?? err.response?.statusText);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  if (gameContext.team && gameContext.team.activated) {
    if (gameContext.game.started) {
      history.push('/game/introduction');
    } else {
      history.push('/game');
    }
  }

  if (gameContext.team && !gameContext.team.activated) {
    return (
      <div className="no-team" key="no-team">
        <h1>Gelukt! Kies je teamnaam</h1>

        <div className="card mb-3">
          <div className="card-body">
            <form onSubmit={ActivateTeamRequest} disabled={saving}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Teamnaam"
                  required={true}
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  autoFocus={!isTouchDevice()}
                />
              </div>
              <Button type="submit" className={`btn btn-info ${saving ? 'loading' : ''}`} disabled={saving}>
                Speel het spel!
                <FontAwesomeIcon icon="spinner" className="loading ml-3 mr-3 fa-spin" />
                <FontAwesomeIcon icon="chevron-right" className="ml-3" />
              </Button>
            </form>
          </div>
        </div>

        {error && <ErrorCard title={'Fout bij het activeren van team'} message={error} />}
      </div>
    );
  }

  return (
    <div className="no-team" key="no-team">
      <h1>Meld je team aan om de Escape Adventure te spelen</h1>

      <div className="card mb-3">
        <div className="card-body">
          <form onSubmit={JoinTeamRequest} disabled={saving}>
            <div className="form-group">
              <label htmlFor="code">Voer de code met 8 letters in die je in de bevestigingsmail hebt ontvangen.</label>
              <input
                type="text"
                className="form-control"
                id="code"
                placeholder="Teamcode"
                required={true}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                autoFocus={!isTouchDevice()}
                minLength="8"
                maxLength="8"
              />
              {/* <small className="form-text text-muted">De teamcaptain heeft deze code gekregen bij het aanmaken van het team.</small> */}
            </div>
            <Button type="submit" className={`btn btn-info ${saving ? 'loading' : ''}`} disabled={saving}>
              Deelnemen aan team
              <FontAwesomeIcon icon="spinner" className="loading ml-3 mr-3 fa-spin" />
              <FontAwesomeIcon icon="chevron-right" className="ml-3" />
            </Button>
          </form>
        </div>
      </div>

      {error && <ErrorCard title={'Fout bij het aanmelden bij team'} message={error} />}
    </div>
  );
};

export default TeamJoin;
