import React from 'react';

const ErrorCard = ({ header, title, message }) => {
  header = header ?? 'Fout';

  return (
    <div className="card text-white bg-danger mb-3">
      {header !== '' && <div className="card-header">{header}</div>}
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{message}</p>
      </div>
    </div>
  );
};

export default ErrorCard;
