import React, { useContext, useEffect, useRef, useState } from 'react';
import useAxios from '../../services/UseAxios';
import { FormatDate } from '../../services/DateHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import RevengeContext from '../../services/RevengeContext';
import Button from 'reactstrap/lib/Button';

const TeamsList = ({ game }) => {
  const [teams, setTeams] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const mountedRef = useRef(true);

  const { journalEvents } = useContext(RevengeContext);
  const { getRequest } = useAxios();

  const fetchData = async () => {
    await getRequest(`/admin/teams/game/${game.id}`)
      .then((result) => {
        setTeams(result.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();

    const journalSub = journalEvents.subscribe({
      next: (data) => {
        if (data || !mountedRef.current) {
          return null;
        }

        fetchData();
      },
    });

    return () => {
      journalSub.dispose();
      mountedRef.current = false;
    };
  }, []);

  if (loading) {
    return null;
  }

  if (!teams) {
    return null;
  }

  return (
    <>
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Code</th>
            <th>Naam</th>
            <th>Email</th>
            <th>Telefoon</th>
            <th>Spelers</th>
            <th>Teamnaam</th>
            <th>Bevestiging</th>
            <th>Betaald</th>
            <th>Borg</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {teams?.map((team) => {
            return (
              <tr
                key={team.id}
                className={`is-clickable`}
                onClick={() => {
                  history.push(`/admin/game/${game.id}/teams/${team.id}`);
                }}
              >
                <td>{team.code}</td>
                <td>{team.contactName}</td>
                <td>{team.email}</td>
                <td>{team.phone}</td>
                <td>{team.numberOfPlayers}</td>
                <td>{team.name}</td>
                <td>
                  <FormatDate date={team.emailConfirmationSent} />
                </td>
                <td>
                  <FormatDate date={team.paid} />
                </td>
                <td>
                  <FormatDate date={team.depositReturned} />
                </td>
                <td>{team.notes}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div>
        <Button type="submit" className="mt-3 btn btn-info float-right" tag={Link} to={`/admin/game/${game.id}/teams/new`}>
          <FontAwesomeIcon icon="chevron-right" className="mr-3" />
          Create new team
        </Button>
      </div>
    </>
  );
};

export default TeamsList;
