import React, { useContext, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import RevengeContext from '../../services/RevengeContext';

const GamePuzzleView = () => {
  const { puzzleCode } = useParams();
  const { gameContext, setPuzzleCode } = useContext(RevengeContext);

  useEffect(() => {
    setPuzzleCode(puzzleCode);
  }, [puzzleCode]);

  if (!gameContext.game.puzzles) {
    return null;
  }

  if (!puzzleCode) {
    return (
      <div className="game-lock">
        <div className="clue">Puzzle code missing</div>
      </div>
    );
  }

  const puzzle = gameContext.game.puzzles.filter((puzzle) => puzzle.code === puzzleCode)[0];

  if (!puzzle) {
    return (
      <div className="game-lock">
        <div className="clue">Puzzle not found</div>
      </div>
    );
  }

  const firstLock = puzzle.locks.filter((x) => !x.completed)[0];
  if (firstLock) {
    return <Redirect to={`/game/puzzle/${puzzleCode}/lock/${firstLock.sequence}`} />;
  }

  return <Redirect to={`/game/puzzle/${puzzleCode}/lock/1`} />;
};

export default GamePuzzleView;
