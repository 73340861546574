import React, { useEffect, useState } from 'react';
import useAxios from '../../services/UseAxios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatParseableDate } from '../../services/DateHelper';

const Times = ({ game, setGame }) => {
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [timesUpdating, setTimesUpdating] = useState(false);
  const { postRequest } = useAxios();

  useEffect(() => {
    if (!game) {
      return;
    }

    setStartTime(FormatParseableDate(game.starts));
    setEndTime(FormatParseableDate(game.ends));
  }, [game]);

  const UpdateTimes = async (e) => {
    e.preventDefault();

    setTimesUpdating(true);

    await postRequest('/admin/times', { gameId: game.id, starts: startTime, ends: endTime })
      .then((result) => {
        setGame({
          ...game,
          starts: result.data.starts,
          ends: result.data.ends,
        });
      })
      .catch((err) => {
        console.log(err);
        //setError(err.response?.data?.message ?? err.response?.data?.detail ?? err.response?.statusText);
      })
      .finally(() => {
        setTimesUpdating(false);
      });
  };

  return (
    <>
      <form className="form" onSubmit={UpdateTimes}>
        <div className="row form-group">
          <label className="col-3 offset-2" htmlFor="starts">
            Starts
          </label>
          <input
            type="text"
            className="form-control col-3"
            required={true}
            autoComplete="off"
            id="starts"
            value={startTime}
            disabled={timesUpdating}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>
        <div className="row form-group">
          <label className="col-3 offset-2" htmlFor="ends">
            Ends
          </label>

          <input
            type="text"
            id="ends"
            className="form-control col-3"
            required={true}
            autoComplete="off"
            value={endTime}
            disabled={timesUpdating}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>
        <div className="row form-group">
          <button type="submit" className="btn btn-info offset-5 col-3" disabled={timesUpdating}>
            Update{` `}
            <FontAwesomeIcon icon="chevron-right" />
          </button>
        </div>
      </form>
    </>
  );
};

export default Times;
