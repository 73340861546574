import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import RevengeContext from '../services/RevengeContext';
import ErrorCard from './Cards/ErrorCard';

const AdminRoute = (props) => {
  const { gameContext } = useContext(RevengeContext);

  if (!gameContext.team?.admin) {
    return <ErrorCard title="Permission denied" message="This is a protected page" />;
  }

  return <Route {...props} render={props.render} />;
};

export default AdminRoute;
