import React from 'react';

const PrivacyView = () => {
  return (
    <div className="privacy-statement">
      <h1>Privacy verklaring</h1>
      <p>
        Vriendenkring De Rijten respecteert de privacy van haar deelnemers aan de escape room, in het bijzonder hun rechten met betrekking
        tot de geautomatiseerde verwerking van persoonsgegevens. Vanwege volledige transparantie hebben wij daarom een beleid geformuleerd
        en geïmplementeerd met betrekking tot deze verwerkingen zelf, het doel ervan alsook de mogelijkheden voor betrokkenen om hun de
        rechten zo goed mogelijk te kunnen uitoefenen.
      </p>
      <p>
        Voor alle aanvullende informatie over de bescherming van persoonsgegevens kunt u terecht op de{' '}
        <a href="https://autoriteitpersoonsgegevens.nl/nl">website van de Autoriteit persoonsgegevens</a>. De aan u beschikbaar gestelde
        privacy policy is de enige versie die van toepassing is, totdat een nieuwe versie de huidige versie vervangt.
      </p>
      <p>
        <br></br>
      </p>
      <h3>Artikel 1 – Wettelijke bepalingen</h3>
      <p>
        Verantwoordelijke voor de verwerking van de persoonsgegevens (Hierna ook: “De beheerder”): Vriendenkring De Rijten, gevestigd te
        Heezerweg 69, 5614 HB Eindhoven, KVK-nummer 17114945.
      </p>
      <p>
        <br></br>
      </p>
      <h3>Artikel 2 – De verwerking van persoonsgegevens</h3>
      <ol>
        <li>
          Uw persoonsgegevens worden verzameld door Vriendenkring De Rijten. Onder de persoonsgegevens worden verstaan: alle informatie over
          een geïdentificeerde of identificeerbare natuurlijke persoon; als identificeerbaar wordt beschouwd een natuurlijke persoon die
          direct of indirect kan worden geïdentificeerd, met name aan de hand van een identificator zoals een naam, een identificatienummer,
          locatiegegevens, een online identificator of een of meer elementen die kenmerkend zijn voor de fysieke, fysiologische, genetische,
          psychische, economische, culturele of sociale identiteit.
        </li>
        <li>
          Wij verwerken de volgende categorieën persoonsgegevens van u:
          <ul>
            <li>Naam</li>
            <li>Team naam</li>
            <li>Emailadres</li>
            <li>Antwoord op vragen in het escape room spel (“combinaties voor het slot”)</li>
            <li>Voortgang in het spel</li>
            <li>Gebruik van de website d.m.v. Google Analytics en Microsoft Application Insights</li>
          </ul>
        </li>
      </ol>
      <p>
        <br></br>
      </p>
      <h3>Artikel 3 – Doel van de verwerking</h3>
      <p>We verzamelen uw persoonsgegevens niet zomaar. Uw persoonsgegevens worden verwerkt voor:</p>
      <ul>
        <li>Toegang geven tot het spel (escape room)</li>
        <li>Het faciliteren van het spel (escape room)</li>
        <li>Overzicht houden van de voortgang per team</li>
        <li>Het oplossen van technische issues voor het gebruik van de website</li>
      </ul>
      <p>
        <br></br>
      </p>
      <h3>Artikel 4 – Registratie persoonsgegevens</h3>
      <p>Uw persoonsgegevens worden geregistreerd in een elektronisch register.</p>
      <p>
        <br></br>
      </p>
      <h3>Artikel 5 – uw rechten met betrekking tot uw gegevens</h3>
      <ol>
        <li>
          Op grand van artikel 13 lid 2 sub b AVG heeft eenieder recht op inzage van en rectificatie of wissing van zijn persoonsgegevens of
          beperking van de hem betreffende verwerking, alsmede het recht tegen de verwerking bezwaar te maken en het recht op
          gegevensoverdraagbaarheid. U kunt deze rechten uitoefenen door contact met ons op te nemen via dewraakvanlind@gmail.com.
        </li>
        <li>
          Ieder verzoek daartoe dient te worden vergezeld van een kopie van een geldig identiteitsbewijs, waarop u uw handtekening heeft
          gezet en onder vermelding van het adres waarop er met u contact kan worden opgenomen. Binnen 1 maand na het ingediende verzoek,
          krijgt u antwoord op uw verzoek. Afhankelijk van de complexiteit van de verzoeken en het aantal van de verzoeken kan deze termijn
          indien nodig met 2 maanden worden verlengd.{' '}
        </li>
      </ol>
      <p>
        <br></br>
      </p>
      <h3>Artikel 6 – Wettelijke verplichtingen</h3>
      <p>
        In geval van enige wet- of regelgeving, waarvan de betrokkene wordt verdacht en waarvoor de autoriteiten persoonsgegevens nodig
        hebben die de beheerder heeft verzameld, worden deze aan hen verstrekt na een uitdrukkelijk en gemotiveerd verzoek van die
        autoriteiten, waarna deze persoonsgegevens mitsdien niet meer onder de bescherming van de bepalingen van deze privacyverklaring
        vallen.{' '}
      </p>
      <p>
        <br></br>
      </p>
      <h3>Artikel 7 – Commerciële aanbiedingen</h3>
      <ol>
        <li>Uw gegevens worden niet gebruikt door partners van de verantwoordelijke voor commerciële doeleinden.</li>
      </ol>
      <p>
        <br></br>
      </p>
      <h3>Artikel 8 – Bewaartermijn gegevens</h3>
      <p>De door de beheerder verzamelde gegevens worden gebruikt en bewaard voor de duur zoals deze bij wet is bepaald</p>

      <p>
        <br></br>
      </p>
      <h3>Artikel 9 – Toepasselijk recht</h3>
      <p>
        Op deze voorwaarden is Nederlands Recht van toepassing. De rechtbank van de vestigingsplaats van de beheerder is exclusief bevoegd
        bij eventuele geschillen omtrent deze voorwaarden, behoudens wanneer hierop uitzondering van toepassing is.
      </p>

      <p>
        <br></br>
      </p>
      <h3>Artikel 10 – Contact</h3>
      <p>Voor verzoeken, vragen, productinformatie of meer informatie, kunt u zich richten tot: dewraakvanlind@gmail.com. </p>
    </div>
  );
};

export default PrivacyView;
