import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import ErrorCard from '../../components/Cards/ErrorCard';
import * as QueryString from 'query-string';
import Leaderboard from '../../components/Leaderboard';
import { FormatDuration } from '../../services/DateHelper';
import RevengeContext from '../../services/RevengeContext';

const GameCompleted = () => {
  const { gameContext } = useContext(RevengeContext);
  const [song, setSong] = useState(new Audio('https://cdn.dewraakvanlind.nl/sounds/heeze-parel-van-brabant.mp3'));

  useEffect(() => {
    playSong();

    return () => {
      pauseSong();
    };
  }, []);

  const pauseSong = () => {
    song.pause();
  };

  const playSong = () => {
    song.volume = 0.1;
    song.play().catch((err) => {});
  };

  if (!gameContext.game?.started) {
    return <ErrorCard header="" title="Fout" message="Het spel is nog niet begonnen" />;
  }

  const qs = QueryString.parse(window.location.search);
  const after = qs.after === '1';

  return (
    <div className="game-completed mb-3">
      <div className="row">
        <div className="col-8">
          <h2 className="orbi">Bedankt!</h2>

          <p className="lead">Bedankt voor je deelname aan De Wraak van Lind - de Escape Adventure</p>

          {after && (
            <div className="exploded">Helaas was het niet gelukt binnen de tijd, maar jullie hebben wel alle puzzels opgelost.</div>
          )}

          {!after && (
            <>
              {gameContext.game.summary.completed && (
                <div className="completed">
                  Yes! Jullie hebben de bom onschadelijk gemaakt in {FormatDuration(gameContext.game.summary.completedIn)}
                </div>
              )}

              {!gameContext.game.summary.completed && (
                <div className="exploded">
                  <div>Helaas! Het is ons niet gelukt om de bom onschadelijk te maken. Wil je nog proberen de puzzels af te maken?</div>

                  <Button className="btn btn-info" to={qs.returnUrl && qs.returnUrl !== '' ? qs.returnUrl : '/game'} tag={Link}>
                    Ik wil de puzzels alsnog afmaken
                    <FontAwesomeIcon icon="chevron-right" className="ml-3" />
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        <div className="col-4">
          <img src="/images/jop-big.jpg" width="100" height="100" style={{ marginTop: '45px' }} />
        </div>
      </div>

      <div className="card mt-5 mb-3">
        <div className="card-body">
          <h3 className="orbi">Graag ontvangen we de volgende materialen retour</h3>
          <ul>
            <li>Kartonnen doos</li>
            <li>Slotje 2x</li>
            <li>Kistje 2x</li>
            <li>Aansteker</li>
            <li>UV-lampje</li>
            <li>Stok kaarten</li>
            <li>80 Puzzelstukjes met ontmantelschema</li>
            <li>Een zakje</li>
          </ul>
          <p>De overige papieren materialen hoeven we niet terug.</p>
        </div>
      </div>

      <h3 className="orbi mt-5">Leaderboard</h3>

      <Leaderboard />

      <h3 className="orbi mt-5">Terugkijken: de bom ontmanteld</h3>
      <video autoPlay={false} controls={true} muted={false} onPlay={pauseSong} onPause={playSong}>
        <source src="https://cdn.dewraakvanlind.nl/videos/bomb-defused.mp4" type="video/mp4" />
        <track label="Nederlands" kind="subtitles" srcLang="nl" src="/subtitles/bomb-defused.vtt" default></track>
      </video>
      <p></p>
      <h3 className="orbi mt-3">Terugkijken: de bom is ontploft</h3>
      <video autoPlay={false} controls={true} muted={false} onPlay={pauseSong} onPause={playSong}>
        <source src="https://cdn.dewraakvanlind.nl/videos/bomb-exploded.mp4" type="video/mp4" />
      </video>
    </div>
  );
};

export default GameCompleted;
