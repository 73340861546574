import React, { useContext, useState } from 'react';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';
import { Collapse, Container, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import RevengeContext from '../services/RevengeContext';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { gameContext } = useContext(RevengeContext);

  return (
    <div className="nav-container">
      <Navbar color="dark" dark expand="md">
        <Container>
          <Link to="/">
            <img src={`/images/logo.png`} />
          </Link>
          <NavbarBrand className="logo" />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink tag={RouterNavLink} to={gameContext.team ? '/game' : '/'} exact activeClassName="router-link-exact-active">
                  Speel het spel
                </NavLink>
              </NavItem>

              {gameContext.game?.started && gameContext.team && !gameContext.team.admin && (
                <>
                  <NavItem>
                    <NavLink tag={RouterNavLink} to="/game/leaderboard">
                      Leaderboard
                    </NavLink>
                  </NavItem>
                </>
              )}

              {gameContext.game?.started && window.location.pathname !== '/' && (
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/instructions" exact activeClassName="router-link-exact-active">
                    Speluitleg
                  </NavLink>
                </NavItem>
              )}

              {gameContext.game?.started && (gameContext.game.ended || gameContext.game.summary?.completed) && (
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/game/completed" exact activeClassName="router-link-exact-active">
                    Eindpagina
                  </NavLink>
                </NavItem>
              )}

              <NavItem>
                <NavLink tag={RouterNavLink} to="/credits" exact activeClassName="router-link-exact-active">
                  Credits
                </NavLink>
              </NavItem>

              {gameContext.team?.admin && (
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/admin" exact activeClassName="router-link-exact-active">
                    Admin
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Collapse>
          {gameContext.team && (
            <div className="team-code">
              <Link to="/team">
                Teamcode: <strong>{gameContext.team.code}</strong>
              </Link>
            </div>
          )}
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
