import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import RevengeContext from '../services/RevengeContext';

const ProtectedRoute = (props) => {
  const { gameContext } = useContext(RevengeContext);

  if (!gameContext.team?.activated) {
    return <Redirect to={`/team/join?returnUrl=${window.location.pathname}`} />;
  }

  return <Route {...props} render={props.render} />;
};

export default ProtectedRoute;
