import React, { useContext, useEffect } from 'react';
import RevengeContext from '../../services/RevengeContext';

const GameDashboardView = () => {
  const { setPuzzleCode, setPuzzleLock } = useContext(RevengeContext);

  useEffect(() => {
    setPuzzleCode(null);
    setPuzzleLock(null);
  }, []);

  return (
    <div className="game-lock">
      <div className="clue">
        <div>Bomb Safety System v0.52</div>
      </div>
    </div>
  );
};

export default GameDashboardView;
