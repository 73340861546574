import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import GameLockQuestion from '../../components/Game/GameLockQuestion';
import GameLockClue from '../../components/Game/GameLockClue';
import RevengeContext from '../../services/RevengeContext';

const GameLockView = () => {
  let { puzzleCode, lockSequence } = useParams();
  const { gameContext, setPuzzleCode, setPuzzleLock } = useContext(RevengeContext);

  useEffect(() => {
    setPuzzleCode(puzzleCode);
    setPuzzleLock({
      puzzleCode: puzzleCode,
      lockSequence: lockSequence,
    });
  }, [puzzleCode, lockSequence]);

  if (!puzzleCode) {
    return (
      <div className="game-lock">
        <div className="clue">Fout: code van het slot ontbreekt. Kun je dit melden bij de makers van het spel?</div>
      </div>
    );
  }

  const puzzle = gameContext.game.puzzles.filter((x) => x.code === puzzleCode)[0];
  if (!puzzle) {
    return (
      <div className="game-lock">
        <div className="clue">Fout: slot niet gevonden. Kun je dit melden bij de makers van het spel?</div>
      </div>
    );
  }

  lockSequence = parseInt(lockSequence);

  const lock = puzzle.locks.filter((x) => x.sequence === lockSequence)[0];
  if (!lock) {
    return (
      <div className="game-lock">
        <div className="clue">Fout: slot niet gevonden. Kun je dit melden bij de makers van het spel?</div>
      </div>
    );
  }

  if (!lock.active) {
    return (
      <div className="game-lock">
        <div className="clue">Dit slot is nog niet beschikbaar. Kraak eerst de andere sloten.</div>
      </div>
    );
  }

  return (
    <div className="game-lock">
      {(puzzle.code === 'BOMB' || !puzzle.completed) && <GameLockClue />}
      {puzzle.completed && puzzle.code !== 'BOMB' && <div className={`clue clue-${puzzle.code}`}></div>}

      <div className="dock-bottom">
        {!lock.completed && <GameLockQuestion puzzle={puzzle} lock={lock} />}

        {lock.completed && !puzzle.completed && <div className="lock-unlocked">{lock.answer}</div>}
        {puzzle.completed && puzzle.code !== 'BOMB' && <div className="lock-unlocked">Symbool ontgrendeld</div>}
        {puzzle.completed && puzzle.code === 'BOMB' && <div className="lock-unlocked">BOM ONTMANTELD!</div>}
      </div>
    </div>
  );
};

export default GameLockView;
