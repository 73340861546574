import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import RevengeContext from '../../services/RevengeContext';

const GameIntroduction = () => {
  const history = useHistory();
  const { sounds } = useContext(RevengeContext);

  const videoEnded = () => {
    history.push('/game');
  };

  return (
    <div className="game-introduction">
      <h3 className="orbi">Het spel begint nu!</h3>

      <video autoPlay={true} onEnded={videoEnded} controls={true} muted={false} poster="/images/video-poster-trailer.jpg">
        <source src="https://cdn.dewraakvanlind.nl/videos/introduction.mp4" type="video/mp4" />
        <track label="Nederlands" kind="subtitles" srcLang="nl" src="/subtitles/introduction.vtt" default></track>
      </video>

      <Button className="btn btn-info mt-3" tag={Link} to={'/game'}>
        Doorgaan naar het spel
        <FontAwesomeIcon icon="chevron-right" className="ml-3" />
      </Button>
    </div>
  );
};

export default GameIntroduction;
