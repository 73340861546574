import React from 'react';

const NotFoundView = () => {
  return (
    <div className="not-found">
      <div>Pagina niet gevonden</div>
    </div>
  );
};

export default NotFoundView;
